
// @ts-nocheck
import locale__vercel_path0_locales_en_json from "../locales/en.json";
import locale__vercel_path0_locales_de_json from "../locales/de.json";
import locale__vercel_path0_locales_es_json from "../locales/es.json";
import locale__vercel_path0_locales_fi_json from "../locales/fi.json";
import locale__vercel_path0_locales_fr_json from "../locales/fr.json";
import locale__vercel_path0_locales_pl_json from "../locales/pl.json";
import locale__vercel_path0_locales_ru_json from "../locales/ru.json";
import locale__vercel_path0_locales_tr_json from "../locales/tr.json";
import locale__vercel_path0_locales_ja_json from "../locales/ja.json";
import locale__vercel_path0_locales_pt_json from "../locales/pt.json";


export const localeCodes =  [
  "en",
  "de",
  "es",
  "fi",
  "fr",
  "pl",
  "ru",
  "tr",
  "ja",
  "pt"
]

export const localeLoaders = {
  "en": [{ key: "../locales/en.json", load: () => Promise.resolve(locale__vercel_path0_locales_en_json), cache: true }],
  "de": [{ key: "../locales/de.json", load: () => Promise.resolve(locale__vercel_path0_locales_de_json), cache: true }],
  "es": [{ key: "../locales/es.json", load: () => Promise.resolve(locale__vercel_path0_locales_es_json), cache: true }],
  "fi": [{ key: "../locales/fi.json", load: () => Promise.resolve(locale__vercel_path0_locales_fi_json), cache: true }],
  "fr": [{ key: "../locales/fr.json", load: () => Promise.resolve(locale__vercel_path0_locales_fr_json), cache: true }],
  "pl": [{ key: "../locales/pl.json", load: () => Promise.resolve(locale__vercel_path0_locales_pl_json), cache: true }],
  "ru": [{ key: "../locales/ru.json", load: () => Promise.resolve(locale__vercel_path0_locales_ru_json), cache: true }],
  "tr": [{ key: "../locales/tr.json", load: () => Promise.resolve(locale__vercel_path0_locales_tr_json), cache: true }],
  "ja": [{ key: "../locales/ja.json", load: () => Promise.resolve(locale__vercel_path0_locales_ja_json), cache: true }],
  "pt": [{ key: "../locales/pt.json", load: () => Promise.resolve(locale__vercel_path0_locales_pt_json), cache: true }]
}

export const vueI18nConfigs = [
  
]

export const nuxtI18nOptions = {
  "experimental": {
    "localeDetector": "",
    "switchLocalePathLinkSSR": false,
    "autoImportTranslationFunctions": false
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false
  },
  "compilation": {
    "jit": true,
    "strictMessage": true,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "",
  "locales": [
    {
      "code": "en",
      "name": "English",
      "files": [
        "locales/en.json"
      ]
    },
    {
      "code": "de",
      "name": "German",
      "fallbackLocale": "en",
      "files": [
        "locales/de.json"
      ]
    },
    {
      "code": "es",
      "name": "Spanish",
      "fallbackLocale": "en",
      "files": [
        "locales/es.json"
      ]
    },
    {
      "code": "fi",
      "name": "Finnish",
      "fallbackLocale": "en",
      "files": [
        "locales/fi.json"
      ]
    },
    {
      "code": "fr",
      "name": "French",
      "fallbackLocale": "en",
      "files": [
        "locales/fr.json"
      ]
    },
    {
      "code": "pl",
      "name": "Polish",
      "fallbackLocale": "en",
      "files": [
        "locales/pl.json"
      ]
    },
    {
      "code": "ru",
      "name": "Russian",
      "fallbackLocale": "en",
      "files": [
        "locales/ru.json"
      ]
    },
    {
      "code": "tr",
      "name": "Turkish",
      "fallbackLocale": "en",
      "files": [
        "locales/tr.json"
      ]
    },
    {
      "code": "ja",
      "name": "Japanese",
      "fallbackLocale": "en",
      "files": [
        "locales/ja.json"
      ]
    },
    {
      "code": "pt",
      "name": "Portuguese",
      "fallbackLocale": "en",
      "files": [
        "locales/pt.json"
      ]
    }
  ],
  "defaultLocale": "en",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "prefix_except_default",
  "lazy": false,
  "langDir": "locales/",
  "detectBrowserLanguage": false,
  "differentDomains": false,
  "baseUrl": "",
  "dynamicRouteParams": false,
  "customRoutes": "page",
  "pages": {},
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false,
  "i18nModules": []
}

export const normalizedLocales = [
  {
    "code": "en",
    "name": "English",
    "files": [
      {
        "path": "locales/en.json"
      }
    ]
  },
  {
    "code": "de",
    "name": "German",
    "fallbackLocale": "en",
    "files": [
      {
        "path": "locales/de.json"
      }
    ]
  },
  {
    "code": "es",
    "name": "Spanish",
    "fallbackLocale": "en",
    "files": [
      {
        "path": "locales/es.json"
      }
    ]
  },
  {
    "code": "fi",
    "name": "Finnish",
    "fallbackLocale": "en",
    "files": [
      {
        "path": "locales/fi.json"
      }
    ]
  },
  {
    "code": "fr",
    "name": "French",
    "fallbackLocale": "en",
    "files": [
      {
        "path": "locales/fr.json"
      }
    ]
  },
  {
    "code": "pl",
    "name": "Polish",
    "fallbackLocale": "en",
    "files": [
      {
        "path": "locales/pl.json"
      }
    ]
  },
  {
    "code": "ru",
    "name": "Russian",
    "fallbackLocale": "en",
    "files": [
      {
        "path": "locales/ru.json"
      }
    ]
  },
  {
    "code": "tr",
    "name": "Turkish",
    "fallbackLocale": "en",
    "files": [
      {
        "path": "locales/tr.json"
      }
    ]
  },
  {
    "code": "ja",
    "name": "Japanese",
    "fallbackLocale": "en",
    "files": [
      {
        "path": "locales/ja.json"
      }
    ]
  },
  {
    "code": "pt",
    "name": "Portuguese",
    "fallbackLocale": "en",
    "files": [
      {
        "path": "locales/pt.json"
      }
    ]
  }
]

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = false

export const DEFAULT_DYNAMIC_PARAMS_KEY = "nuxtI18n"
export const DEFAULT_COOKIE_KEY = "i18n_redirected"
export const SWITCH_LOCALE_PATH_LINK_IDENTIFIER = "nuxt-i18n-slp"
