<template>
  <div class="modal-promo-items w-full p-4 flex flex-col md:flex-row">
    <div
      v-for="(item, index) in blok.itemsData"
      :key="(item.id, index)"
      class="relative mx-auto"
    >
      <img :src="parsedImageUrl(item)" />
      <div
        class="image-label absolute left-0 right-0 bottom-0 mb-8 inline-block"
      >
        <span class="py-1 px-2 bg-brand-black text-white text-xs">
          {{ item.image_text }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ModalPromoItems',
  props: {
    blok: {
      type: Object,
      required: true,
    },
  },
  computed: {},
  created() {
    // console.log(this.blok)
  },
  methods: {
    parsedImageUrl(item) {
      return item.image_asset &&
        item.image_asset.filename &&
        item.image_asset.filename !== ''
        ? item.image_asset.filename
        : item.image_url
    },
  },
}
</script>
