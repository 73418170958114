import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

export default defineNuxtPlugin(() => {
  // Your web app's Firebase configuration
  const firebaseConfig = {
    apiKey: "AIzaSyDkGmFNUqWGjwXa-K5FN7tXH-GCfPRvQZI",
    authDomain: "metso-liveroom.firebaseapp.com",
    projectId: "metso-liveroom",
    storageBucket: "metso-liveroom.appspot.com",
    messagingSenderId: "58106060388",
    appId: "1:58106060388:web:de78a7c5e3c2c435f5c971",
  };

  const app = initializeApp(firebaseConfig);

  // const analytics = getAnalytics(app)

  return {
    provide: {
      auth: getAuth(app),
      firestore: getFirestore(app),
    },
  };
});
