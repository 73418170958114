<template>
  <div class="w-full profit-calculator">
    <div v-if="showIntro" class="start">
      <div class="ninefifty fixed-size">
        <video id="animation" loop muted>
          <source :src="blok.Animation.filename" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
      <div class="content-lift">
        <h2>Profit calculator</h2>
        <p>
          Press start and check how much money you will make running this plant.
        </p>
        <p>
          Plant details:<br />
          LT120E + LT200HPX + ST4.10E – Feed hard rock (granite) 220tph – End
          products: 0-8mm, 8-16mm, 16-32mm – Annual operating hours
          {{ working_hours_x_availability }}h
        </p>
        <div class="my-4 py-4">
          <button
            class="btn btn-basic focus:outline-none leading-tight"
            @click="runCalculation()"
          >
            Start
          </button>
        </div>
      </div>
    </div>
    <div v-if="showCalculation" class="calculation">
      <div v-if="!counted" class="ninefifty fixed-size">
        <video id="animation" loop muted>
          <source :src="blok.Animation.filename" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
      <div v-if="counted" class="results ninefifty">
        <div class="content-lift">
          <h2>Results after {{ years }} years</h2>
          <p>
            Drag the time slider to see how values change over time. Click the
            icons for more details. All values are calculated for income of
            {{ formatMoney(income_per_ton, false) }}/t.
            <a href="#" @click="runCustom()">Change</a> <br />
            Calculation results are indicative and Metso does not quarantee any
            of the calculation results.
          </p>
          <p>Need a custom calculation? Contact us!</p>
        </div>
        <div class="grid grid-cols-3 gap-4">
          <div>
            <div class="iconholder hilite">
              <img
                src="/calculator/icon_investment.svg"
                class="calc_icon"
                @click="runInvestment()"
              />
            </div>
            <h3>Investment<br />{{ formatMoney(investment) }}</h3>
          </div>
          <div>
            <div class="iconholder hilite">
              <img
                src="/calculator/icon_costs.svg"
                class="calc_icon"
                @click="runCosts()"
              />
            </div>
            <h3>Cost of operation<br />{{ formatMoney(totalCosts) }}</h3>
          </div>
          <div>
            <div class="iconholder hilite">
              <img
                src="/calculator/icon_profit.svg"
                class="calc_icon"
                @click="runProfit()"
              />
            </div>
            <h3>Profit<br />{{ formatMoney(totalProfit) }}</h3>
          </div>
        </div>
      </div>
      <div class="ninefifty">
        <div>
          <div class="cf">
            <div
              class="bar in my-1"
              :title="formatMoney(investment)"
              :style="getBarCSSWidth(getPercentage(investment, hundredPercent))"
            >
              <span>Investment</span>
            </div>
          </div>
          <div class="cf">
            <div
              class="bar rv my-1"
              :title="formatMoney(resaleValue)"
              :style="
                getBarCSSWidth(getPercentage(resaleValue, hundredPercent))
              "
            >
              <span>MO Lokotrack resale value</span>
            </div>
          </div>
          <div class="cf">
            <div
              class="bar vr my-1"
              :title="formatMoney(investment_value_reduction)"
              :style="
                getBarCSSWidth(
                  getPercentage(investment_value_reduction, hundredPercent)
                )
              "
            >
              <span>Investment value reduction</span>
            </div>
          </div>
          <div class="cf">
            <div
              class="bar co my-1"
              :title="formatMoney(totalCosts)"
              :style="getBarCSSWidth(getPercentage(totalCosts, hundredPercent))"
            >
              <span>Cost of operation</span>
            </div>
          </div>
          <div class="cf">
            <div
              class="bar pr my-1"
              :title="formatMoney(totalIncome)"
              :style="
                getBarCSSWidth(getPercentage(totalIncome, hundredPercent))
              "
            >
              <span>Income</span>
            </div>
          </div>
        </div>
        <div class="my-4 relative checkbox-container">
          <label class="checkbox_input my-4 leading-tight">
            Equipment Protection Services (<abbr
              title="Equipment Protection Services"
              @click="runEps()"
              >EPS</abbr
            >)
            <input
              id="EPS"
              v-model="eps"
              class="rounded-none focus:outline-none text-brand-pink"
              type="checkbox"
              name="EPS"
              value="true"
            />
            <span class="checkmark"></span>
          </label>
        </div>
        <div class="range" title="Change years using the slider">
          <div class="start">0 YEARS</div>
          <div class="end">5 YEARS</div>
          <input
            v-model="years"
            type="range"
            min="0"
            max="5"
            step="1"
            class="form-range"
            list="ticks1"
            :disabled="counted == false"
            @change="fillData"
          />
          <datalist id="ticks1">
            <option value="0"></option>
            <option value="1"></option>
            <option value="2"></option>
            <option value="3"></option>
            <option value="4"></option>
            <option value="5"></option>
          </datalist>
        </div>
      </div>
    </div>
    <div v-if="showInvestment" class="investment infobox">
      <div class="go-back" @click="runBack()">
        <img src="/calculator/go-back.svg" />
      </div>
      <div class="ninefifty">
        <h2>Investment over {{ years }} years</h2>
        <div class="grid grid-cols-2 gap-4">
          <div>
            <div class="iconholder">
              <img src="/calculator/icon_investment.svg" class="calc_icon" />
            </div>
            <h3>Initial investment {{ formatMoney(investment) }}</h3>
            <p>
              Initial investment includes investment and operation costs. After
              {{ years }}
              years investment is {{ getPercentage(investment, totalCosts) }}%
              of total costs.
            </p>
          </div>
          <div>
            <div class="iconholder">
              <img src="/calculator/icon_resalevalue.svg" class="calc_icon" />
            </div>
            <h3>
              MO Lokotrack resale value {{ investment_salvage_value * 100 }}%
            </h3>
            <p>
              Best resale value in the market. With Equipment Protection
              Services you ensure best possible resale value.
            </p>
          </div>
        </div>
      </div>
    </div>
    <div v-if="showCosts" class="cost infobox">
      <div class="go-back" @click="runBack()">
        <img src="/calculator/go-back.svg" />
      </div>
      <h2>Costs over {{ years }} years</h2>
      <div class="doughnutcontainer">
        <Doughnut :data="doughnutChartData" :options="doughnutChartOptions" />
      </div>
    </div>
    <div v-if="showProfit" class="profit infobox">
      <div class="go-back" @click="runBack()">
        <img src="/calculator/go-back.svg" />
      </div>
      <div class="ninefifty">
        <h2>Profit over {{ years }} years</h2>
        <div class="grid grid-cols-2 gap-4">
          <div>
            <div class="iconholder">
              <img src="/calculator/icon_profit.svg" class="calc_icon" />
            </div>
            <h3>Profit {{ formatMoney(totalProfit) }}</h3>
            <p>
              Your profit after {{ years }} years of operation. Resale value of
              your Lokotrack® equipment is
              {{ investment_salvage_value * 100 }}% of the initial investment.
            </p>
          </div>
          <div>
            <div class="iconholder">
              <img src="/calculator/icon_production.svg" class="calc_icon" />
            </div>
            <h3>Total production {{ formatDecimal(totalProduction) }}t</h3>
            <p>
              {{ formatDecimal(totalProduction) }} tonnes of material means
              {{ formatDecimal((totalProduction / 400).toFixed(0)) }} average
              homes (400t/average home) –
              {{ formatDecimal((totalProduction / 15000).toFixed(0)) }} school
              buildings ({{ formatDecimal(15000) }}t/school) –
              {{ formatDecimal((totalProduction / 24000).toFixed(0)) }}km of
              highway lane ({{ formatDecimal(24000) }}t/km).
            </p>
          </div>
        </div>
      </div>
    </div>
    <div v-if="showEps" class="eps infobox">
      <div class="go-back" @click="runBack()">
        <img src="/calculator/go-back.svg" />
      </div>
      <div class="ninefifty">
        <h2>EPS - Equipment Protection Services</h2>
        <div style="width: 600px; margin: 0 auto">
          <img :src="blok.Image.filename" alt="" />
        </div>
        <div class="grid grid-cols-2 gap-4">
          <div>
            <h3>Equipment Protection Services</h3>
            <p>
              As part of Metso Life Cycle Services for Aggregates, Equipment
              Protection Services (EPS) brings you added protection for your
              most valuable assets, fixed or mobile. This comprehensive plan
              includes extended warranties, scheduled inspections with
              Metso-certified technicians and Metso Metrics Services, our remote
              monitoring and data visualization solution
            </p>
          </div>
          <div>
            <h3>Need a custom calculation?</h3>
            <p>
              If you need a detailed breakdown or a calculation for a custom
              configuration, please contact us or book an instant meeting! Our
              specialists will contact you at the earliest convenience.
            </p>
          </div>
        </div>
      </div>
    </div>
    <div v-if="showCustom" class="custom infobox">
      <div class="go-back" @click="runBack()">
        <img src="/calculator/go-back.svg" />
      </div>
      <div class="ninefifty">
        <h2>Customize</h2>
        <p>Enter your income per tonne</p>
        <p class="income_per_t">
          <input v-model="income_per_ton" type="number" step="1" />€/t
        </p>
        <p>
          You can enter your own income per tonne for customized results. This
          value is used for calculation only and is not saved. Default value is
          {{ formatMoney(5, false) }}/t.
        </p>
        <div class="my-4 py-4"><Button label="Save" @click="runBack()" /></div>
        <p>Need a custom calculation? Contact us!</p>
      </div>
    </div>
  </div>
</template>

<script>
import { ArcElement, Chart as ChartJS, Legend, Tooltip } from "chart.js";
import { Doughnut } from "vue-chartjs";

ChartJS.register(ArcElement, Tooltip, Legend);

export default {
  components: {
    Button: () => import("@/components/ui/ButtonLegacy"),
    Doughnut,
  },
  props: {
    blok: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      // UI Related
      years: 0,
      eps: true,
      counted: false,
      showIntro: true,
      showInvestment: false,
      showCalculation: false,
      showCosts: false,
      showProfit: false,
      showEps: false,
      showCustom: false,
      // Investment
      investment: 1375000, // per train
      investment_depreciation_period: 5, // years
      // Operation
      working_days_per_year: 250, // days
      shifts_per_day: 1, // each
      shift_hours: 10, // h
      operators_per_shift: 3, // each
      operator_salary_w_social_costs: 35, // per hour
      fuel_consumption: 75, // plant, l/h
      utilisation_rate: 0.8, // 80%
      energy_cost_per_l: 1.5, // €/l
      scheduled_maintenance_costs: 16800, // per plant
      protective_wears_per_1000: 18860, // €/1000h per plant
      wear_part_cost_per_1000: 31800, // €/1000h per plant
      plant_top_capacity: 220, // t/h (from bruno)
      equipment_protection_service: 40800, // €, for train per five years (60 months) (monthly fees 260 + 210 + 210)
      // income
      income_per_ton: 5, // €
      // Chart (UI)
      doughnutChartData: {},
      doughnutChartOptions: {
        legend: { position: "right" },
        responsive: true,
        maintainAspectRatio: false,
      },
    };
  },
  computed: {
    repair_cost_per_1000() {
      return this.investment * 0.01; // €/1000h per plant
    },
    investment_salvage_value() {
      return this.eps ? 0.45 : 0.4;
    },

    currentRoutePath() {
      return this.$route.path;
    },
    currentLang() {
      return this.$i18n.locale;
    },

    /*
    --------------------------------
      INTERMEDIATE RESULTS
    --------------------------------
    */

    // Annual fixed costs
    labour_cost() {
      return (
        this.operator_salary_w_social_costs *
        this.working_hours *
        this.operators_per_shift
      );
    },
    investment_value_reduction() {
      return (
        ((this.investment * (1 - this.investment_salvage_value)) /
          this.investment_depreciation_period) *
        this.years
      );
    },
    annual_equipment_protection_service() {
      return (
        this.equipment_protection_service / this.investment_depreciation_period
      );
    },
    // Annual variable costs
    energy_cost() {
      return (
        this.energy_cost_per_l *
        this.fuel_consumption *
        this.working_hours_x_availability
      );
    },
    annual_scheduled_maintenance_costs() {
      return (
        (this.scheduled_maintenance_costs / 1000) *
        this.working_hours_x_availability
      );
    },
    annual_repair_cost() {
      return (
        (this.repair_cost_per_1000 / 1000) * this.working_hours_x_availability
      );
    },
    wear_part_cost() {
      return (
        ((this.protective_wears_per_1000 + this.wear_part_cost_per_1000) /
          1000 /
          this.plant_top_capacity) *
        this.annual_production
      );
    },
    // Working time per year
    working_hours() {
      return (
        this.working_days_per_year * this.shift_hours * this.shifts_per_day
      );
    },
    working_hours_x_availability() {
      return this.working_hours * this.utilisation_rate;
    },

    /*
    --------------------------------
      RESULTS
    --------------------------------
    */
    // Costs
    all_annual_fixed_costs() {
      return (
        this.labour_cost +
        this.investment_value_reduction +
        this.annual_equipment_protection_service
      );
    },
    all_annual_variable_costs() {
      return (
        this.energy_cost +
        this.annual_scheduled_maintenance_costs +
        this.annual_repair_cost +
        this.wear_part_cost
      );
    },
    // Working time
    annual_productive_working_hours() {
      return this.working_hours_x_availability;
    },
    // LT train production
    annual_production() {
      return this.plant_top_capacity * this.annual_productive_working_hours;
    },

    // UI related
    hundredPercent() {
      return (
        this.investment + this.totalCosts + this.totalIncome + this.resaleValue
      );
    },
    totalCosts() {
      return (
        (this.all_annual_fixed_costs + this.all_annual_variable_costs) *
        this.years
      );
    },
    totalIncome() {
      return this.totalProduction * this.income_per_ton;
    },
    totalProfit() {
      return this.totalIncome - this.totalCosts;
    },
    resaleValue() {
      const resaleVal =
        parseInt(this.years) === 0
          ? 0
          : this.investment * this.investment_salvage_value;
      return resaleVal;
    },
    totalProduction() {
      return this.annual_production * this.years;
    },
  },
  methods: {
    fillData() {
      this.doughnutChartData = {
        labels: [
          `Labour cost: 3 operators, 35€/h`,
          `Investment value reduction`,
          `Equipment Protection Service (EPS)`,
          "Energy cost: operated with diesel engines 1,5€/l",
          "Scheduled maintenance costs",
          "Repair costs",
          "Wear part cost",
        ],
        datasets: [
          {
            label: "Costs over 5 years",
            backgroundColor: [
              "#000000",
              "#4E2098",
              "#885AD1",
              "#A275EA",
              "#B692F1",
              "#CEB5F6",
              "#E4D6FB",
            ],
            data: [
              Math.round(this.labour_cost * this.years),
              Math.round(this.investment_value_reduction * this.years),
              Math.round(this.annual_equipment_protection_service * this.years),
              Math.round(this.energy_cost * this.years),
              Math.round(this.scheduled_maintenance_costs * this.years),
              Math.round(this.annual_repair_cost * this.years),
              Math.round(this.wear_part_cost * this.years),
            ],
          },
        ],
      };
    },
    formatMoney(money, round = true) {
      const number = round ? Math.round(money / 10000) * 10000 : money;
      const formatter = new Intl.NumberFormat("fr-FR", {
        style: "currency",
        currency: "EUR",
        minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
      });
      return formatter.format(number);
    },
    formatDecimal(number) {
      const formatter = new Intl.NumberFormat("fr-FR", {
        style: "decimal",
        minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
      });
      return formatter.format(number);
    },
    getBarCSSWidth(width) {
      return "width: " + width + "%;";
    },
    runBack() {
      this.showCosts = false;
      this.showProfit = false;
      this.showInvestment = false;
      this.showCustom = false;
      this.showEps = false;
    },
    runEps() {
      this.showCosts = false;
      this.showProfit = false;
      this.showInvestment = false;
      this.showCustom = false;
      this.showEps = true;
    },
    runCustom() {
      this.showCosts = false;
      this.showProfit = false;
      this.showInvestment = false;
      this.showCustom = true;
      this.showEps = false;
    },
    runCosts() {
      this.showCosts = true;
      this.showProfit = false;
      this.showInvestment = false;
      this.showCustom = false;
      this.showEps = false;
      this.fillData();
    },
    runProfit() {
      this.showCosts = false;
      this.showProfit = true;
      this.showInvestment = false;
      this.showCustom = false;
      this.showEps = false;
    },
    runInvestment() {
      this.showCosts = false;
      this.showProfit = false;
      this.showInvestment = true;
      this.showCustom = false;
      this.showEps = false;
    },
    runCalculation() {
      document.getElementById("animation").play();
      console.log("play");
      this.showIntro = false;
      this.showCalculation = true;
      this.counted = false;
      const intervalId = setInterval(() => {
        this.years += 1;
        if (this.years === 5) {
          clearInterval(intervalId);
          this.counted = true;
          document.getElementById("animation").pause();
        }
      }, 1000);
    },
    getPercentage(partialValue, totalValue) {
      if (totalValue === 0) return 100;
      return ((100 * partialValue) / totalValue).toFixed(2);
    },
    getResalePercentage() {
      return ((100 * this.resaleValue) / this.hundredPercent).toFixed(2);
    },
  },
};
</script>

<style lang="scss" scoped>
.cf {
  overflow: hidden;
}

video {
  width: 100%;
  max-height: 100%;
  box-sizing: border-box;
}

.doughnut-chart {
  width: 80%;
  height: 80%;
  margin: auto;
  margin-top: 30px;
}
.range {
  margin: 0 75px;
  position: relative;
  .start {
    line-height: 24px;
    top: 0;
    left: -75px;
    position: absolute;
  }
  .end {
    line-height: 24px;
    top: 0;
    right: -75px;
    position: absolute;
    text-align: right;
  }
  datalist {
    z-index: -1;
    position: relative;
    display: flex;
    justify-content: space-between;
    height: auto;
    bottom: 24px;
    /* disable text selection */
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
    /* disable click events */
    pointer-events: none;
    option {
      width: 2px;
      height: 14px;
      min-height: 10px;
      border-radius: 0;
      white-space: nowrap;
      padding: 0;
      background-color: #e7e7e7;
      &:first-child,
      &:last-child {
        margin-top: -4px;
        height: 22px;
      }
    }
  }
}

.form-range {
  width: 100%;
  height: 1.5rem;
  padding: 0;
  background-color: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  &:focus {
    outline: 0;
    &::-webkit-slider-thumb {
      border-radius: 100%;
      box-shadow:
        0 0 0 1px #fff,
        0 0 0 0.25rem rgba(13, 110, 253, 0.25);
    }
    &::-moz-range-thumb {
      border-radius: 100%;
      box-shadow:
        0 0 0 1px #fff,
        0 0 0 0.25rem rgba(13, 110, 253, 0.25);
    }
  }
  &::-moz-focus-outer {
    border: 0;
  }
  &::-webkit-slider-thumb {
    width: 25px;
    height: 25px;
    margin-top: -10px;
    border: 0;
    background-image: url("/calculator/icon_hexagon.svg");
    background-size: contain;
    background-repeat: no-repeat;
    -webkit-appearance: none;
    appearance: none;
  }
  &::-moz-range-thumb {
    width: 25px;
    height: 25px;
    margin-top: -10px;
    border: 0;
    background-image: url("/calculator/icon_hexagon.svg");
    background-size: contain;
    background-repeat: no-repeat;
    -moz-appearance: none;
    appearance: none;
  }
  &::-webkit-slider-runnable-track {
    width: 100%;
    height: 2px;
    color: transparent;
    cursor: pointer;
    background-color: #e7e7e7;
    border-color: transparent;
    border-radius: 0;
  }
  &::-moz-range-track {
    width: 100%;
    height: 2px;
    color: transparent;
    cursor: pointer;
    background-color: #e7e7e7;
    border-color: transparent;
    border-radius: 0;
  }
  &:disabled {
    pointer-events: none;
    &::-webkit-slider-thumb {
      background-color: transparent;
    }
    &::-moz-range-thumb {
      background-color: transparent;
    }
  }
}

.profit-calculator {
  p {
    margin-bottom: 0.5em;
  }
  .income_per_t {
    font-size: 40px;
    input {
      width: 100px;
    }
  }
  .iconholder {
    height: 120px;
    margin-bottom: 30px;
    .calc_icon {
      max-height: 100px;
      max-width: 100px;
      margin: 0 auto 20px auto;
      position: relative;
      top: 50%;
      transform: translateY(-50%);
    }
    &.hilite:hover {
      cursor: pointer;
      background-color: #f1f1f1;
    }
  }
  .ninefifty {
    overflow: hidden;
  }
  .infobox {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 250px;
    background-color: #ffffff;
    padding: 20px;
    &.eps {
      bottom: 0;
    }
    .go-back {
      position: absolute;
      top: 20px;
      left: 20px;
      width: 40px;
      height: 28px;
      z-index: 100;
    }
  }
  @media (min-width: 1200px) {
    h2 {
      font-size: 52px;
      line-height: 60px;
    }
    h3 {
      font-size: 28px;
      line-height: 32px;
    }
    .content-lift {
      width: 600px;
      margin: 0 auto;
    }
    .ninefifty {
      width: 950px;
      margin: 0 auto;
    }
    .fixed-size {
      width: 768px;
      height: 300px;
    }
    .doughnutcontainer {
      position: absolute;
      top: 75px;
      left: 50%;
      transform: translateX(-50%);
      width: 650px;
      height: 400px;
      margin: 0 auto;
    }
    .infobox .go-back {
      top: 40px;
      left: calc((1300px - 950px) / 2);
    }
  }

  padding: 20px;
  .bar {
    float: left;
    padding: 2px 0;
    text-align: right;
    border-left: 1px solid #000;
    border-right: 1px dashed #000;
    font-size: 14px;
    overflow: visible;
    height: 18px;
    position: relative;
    transition: width 1s;
    span {
      position: absolute;
      left: 100%;
      white-space: nowrap;
      margin-right: 5px;
      margin-left: 5px;
    }
    &.rv {
      background-color: #00e2b2;
    }
    &.in {
      background-color: #00e2b2;
    }
    &.co {
      background-color: #c4c4c4;
    }
    &.vr {
      background-color: #c4c4c4;
    }
    &.pr {
      background-color: #00e2b2;
    }
  }
}

.checkbox-container {
  display: inline-block;
  margin: 0 auto;
}

/* Customize the label (the checkbox_input) */
.checkbox_input {
  display: block;
  position: relative;
  padding-left: 2rem;
  cursor: pointer;
  user-select: none;
}

/* Hide the browsers default checkbox */
.checkbox_input input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #fff;
  border: 1px solid #b8b8b8;
}

/* When the checkbox is checked, add a background */
.checkbox_input input:checked ~ .checkmark {
  background-image: url("@/assets/img/register_form_check.svg");
  background-repeat: no-repeat;
  background-size: 14px;
  background-position: center;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkbox_input input:checked ~ .checkmark:after {
  display: block;
}
</style>
