<template>
  <div v-editable="blok">
    <div class="event-scene__header">
      <div class="event-scene__header-title">
        {{ eventTitle }}
      </div>
      <Button
        class="contact-button"
        :label="'Meeting request'"
        @click="showContact"
      />
    </div>
    <div class="event-scene__footer">
      <div :class="['event-scene__footer-bg']"></div>
      <button
        v-if="!checkMobile && eventIframeStatus"
        class="event-scene__footer-button event-scene__footer-button--360"
      >
        <span class="tooltiptext">360 view</span>
        <SVGIcon360 />
      </button>
      <button
        v-if="!checkMobile && eventIframeStatus"
        class="event-scene__footer-button"
        @click="toggleFullscreen()"
      >
        <span class="tooltiptext tooltiptext__fullscreen">Fullscreen</span>
        <SVGIconFullscreen :fullscreenStatus="fullscreenStatus" />
      </button>
    </div>
    <div v-if="eventIframeStatus" class="event-scene__drag-info">
      <div class="event-scene__drag-info">
        <DragSvgIcon />
        <p>Click & hold to look around</p>
      </div>
    </div>
    <div class="flex justify-center items-center w-screen h-screen">
      <client-only placeholder="Loading...">
        <div v-if="loading">
          <Spinner />
        </div>
        <div v-else-if="pano" class="w-screen h-screen">
          <component
            :is="block.component"
            v-for="block in onlyContentHotspots"
            :id="block._uid"
            :key="block._uid"
            :blok="block"
            :navi-data="naviData"
            @navichange="handleNaviChange($event)"
          />

          <component
            :is="blok.component"
            v-for="blok in otherOverlays"
            :key="blok._uid"
            :blok="blok"
          />
        </div>
        <div v-else style="z-index: 1">User not authorized</div>
        <!-- <div v-else class="event-scene__error"></div> -->
      </client-only>
    </div>
    <div
      v-if="streamOverOverlay"
      class="stream-over flex justify-center items-center w-screen h-screen fixed"
    >
      <div class="stream-over__container">
        <button
          class="stream-over__close-button"
          @click="toggleStreamOverlay(false)"
        >
          <CloseIcon />
        </button>
        <div class="stream-over__title">Thank you for watching</div>
        <div class="stream-over__body">
          <p>
            The live event has now ended. You can get to know details inside the
            Lokotrack™ Liveroom.
          </p>
        </div>
        <Button
          class="stream-over__button"
          :label="'Go to Lokotrack™ Liveroom'"
          @click="goToShowroom()"
        />
      </div>
    </div>
  </div>
</template>

<script>
import DragSvgIcon from "@/components/icons/DragSvgIcon.vue";
import SVGIcon360 from "@/components/icons/SVGIcon360.vue";
import SVGIconFullscreen from "@/components/icons/SVGIconFullscreen.vue";
// import Cookies from "js-cookie";

const eventCookie = useCookie("event_data");

const router = useRouter();

export default {
  components: {
    Button: () => import("@/components/ui/ButtonLegacy"),
    CloseIcon: () => import("@/components/ui/navigation/CloseIcon"),
    Spinner: () => import("@/components/ui/Spinner"),
    SVGIcon360,
    SVGIconFullscreen,
    DragSvgIcon,
  },
  props: {
    blok: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      eventToken: null,

      pano: null,
      startView: null,
      krpanoHLookAt: null,
      pageNow: null,
      homeStep: "",
      naviData: null,
      headerNavi: false,

      loading: true,
      event: null,
      eventStatus: "pre_launch",
      eventTitle: "",
      eventErr: null,
      unsubscribe: null,

      fullscreenStatus: false,

      eventIframeStatus: false,
      checkMobile: false,

      streamOverOverlay: false,

      isFirefox: false,
    };
  },
  head() {
    return {
      meta: [
        {
          hid: "description",
          name: "description",
          content:
            "See live event in the Metso Liveroom. Check it out 🥰🤩 See Metso 3D 360° product demos and visit virtual stands @Metso #AggregatesLiveroom",
        },
        {
          hid: "og:description",
          name: "og:description",
          content:
            "See live event in the Metso Liveroom. Check it out 🥰🤩 See Metso 3D 360° product demos and visit virtual stands @Metso #AggregatesLiveroom",
        },
      ],
    };
  },
  computed: {
    currentRoute() {
      if (this.$route.name) {
        return this.$route.name.split("___")[0];
      }
      return "";
    },
    currentRoutePath() {
      return this.$route.path;
    },
    currentLang() {
      return this.$i18n.locale;
    },
    currentSpace() {
      return this.$route.path.includes("/lokotrack")
        ? "lokotrack"
        : "nordtrack";
    },
    onlyContentHotspots() {
      if (this.blok.content.hotspots) {
        return this.blok.content.hotspots.filter(function (hotspot) {
          return hotspot.component === "HotspotOpener";
        });
      }
      return [];
    },
    otherOverlays() {
      if (this.blok.content.hotspots) {
        return this.blok.content.hotspots.filter(function (hotspot) {
          return hotspot.component === "EventPanel";
        });
      }
      return [];
    },
  },
  watch: {
    user(oldUser, newUser) {
      // console.log('event user changed: ', oldUser, newUser)
      if (this.readyToHandle && this.user) {
        // console.log('redirecting user to correct place')
        // this.handleAuthorizedUser()
        setTimeout(this.handleAuthorizedUser, 1000);
      }
    },
  },
  created() {
    if (this.blok.content.hotspots) {
      this.naviData = this.onlyContentHotspots.map((hotspot) => {
        return {
          id: hotspot._uid,
          title: hotspot.name,
        };
      });
    }
  },
  beforeMount() {},
  mounted() {
    try {
      if (navigator.userAgent.toLowerCase().includes("firefox")) {
        // Do Firefox-related activities
        this.isFirefox = true;
      }
    } catch (error) {
      this.isFirefox = false;
    }
    try {
      if (this.$route.query.event) {
        this.event = this.$route.query.event;
      } else {
        this.event = this.getLastPathPart(this.$route.path);
      }
    } catch (error) {
      console.log(error);
    }
    if (this.$route.query.token) {
      const data = JSON.stringify({
        token: this.$route.query.token,
        eventName: this.event,
        dismiss: false,
      });
      eventCookie.value = data;
      // Cookies.set("event_data", data, { expires: 1 });
      this.eventToken = this.$route.query.token;
    } else {
      // const cookie = Cookies.get("event_data");

      if (eventCookie.value) {
        this.eventToken = JSON.parse(
          eventCookie.value.replace("%22", '"').replace("%2C", ",")
        ).token;
      } else {
        this.eventToken = "db39e3dbe5215d4a";
      }
    }

    if (this.eventToken) {
      this.validateTokenAndSignIn();
    } else {
      router.push("/");
    }

    if (window.innerWidth > 820) {
      const prefixes = ["", "moz", "webkit", "ms"];
      const that = this;
      prefixes.forEach(function (prefix) {
        window.addEventListener(prefix + "fullscreenchange", () => {
          that.fullscreenchanged();
        });
      });
    } else {
      this.goToTheaterMode();
    }
  },

  beforeDestroy() {
    const prefixes = ["", "moz", "webkit", "ms"];
    const that = this;
    prefixes.forEach(function (prefix) {
      window.removeEventListener(prefix + "fullscreenchange", () => {
        that.fullscreenchanged();
      });
    });

    try {
      this.unsubscribe();
    } catch (error) {
      console.log("");
    }
  },

  methods: {
    showContact() {
      const modalElement = document.getElementById("contact-modal");
      if (modalElement) {
      }
      const modal = this.$bootstrap.Modal.getInstance(modalElement);
      modal.show();
    },
    getLastPathPart(path) {
      // Remove any trailing slashes and then split the path by '/'
      const cleanedPath = path.replace(/\/+$/, ""); // Remove trailing slashes
      const pathParts = cleanedPath.split("/");
      return pathParts[pathParts.length - 1];
    },
    fullscreenchanged() {
      if (
        document.fullscreenElement ||
        document.mozFullScreenElement ||
        document.webkitFullscreenElement ||
        document.msFullscreenElementt
      ) {
        if (!document.getElementById("fullscreen-toggle")) {
          const button = document.createElement("button");
          button.id = "fullscreen-toggle";
          button.innerHTML = `
        <svg width="37" height="37" viewBox="0 0 37 37" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M24.5372 24.537V36.8055H28.6267V28.6265H36.8057V24.537H24.5372ZM1.90735e-06 24.537V28.6265H8.17901V36.8055H12.2685V24.537H1.90735e-06ZM24.5372 -0.179199V12.0893H36.8057V7.99981H28.6267V-0.179199H24.5372ZM8.17901 -0.179199V7.99981H0V12.0893H12.2685V-0.179199H8.17901Z" fill="#00E2B2"/>
</svg>
        `;
          button.style.position = "absolute";
          button.style.bottom = "30px";
          button.style.right = "25px";
          button.style.height = "25px";
          button.style.width = "25px";
          button.onclick = () => {
            this.toggleFullscreen();
          };
          button.ontouchstart = () => {
            this.toggleFullscreen();
          };
          const span = document.createElement("span");
          span.classList.add("tooltiptext");
          span.classList.add("tooltiptext__fullscreen");
          span.innerHTML = "Exit fullscreen";
          button.appendChild(span);
          document.getElementById("iframe-wrapper").appendChild(button);
        }
      } else {
        document.getElementById("fullscreen-toggle").remove();
      }
    },
    async validateTokenAndSignIn() {
      if (this.$fire.auth.currentUser) {
        await this.$fire.auth.signOut();
        console.log("signed out.");
      }
      let token = this.eventToken;
      if (token.length > 16) {
        token = token.substr(0, 16);
      }
      if (token.length === 16 && /^[0-9a-zA-Z_-]+$/.test(token)) {
        await setTimeout(() => {}, 100);
        this.readyToHandle = true;
        await this.$fire.auth
          .signInWithEmailAndPassword(`mo_${token}@moliveroom.com`, token)
          .then((res) => {
            console.log("signed");
            const data = {
              token,
              eventName: this.event,
              dismiss: false,
            };
            eventCookie.value = JSON.stringify(data);
            // Cookies.set("event_data", JSON.stringify(data), { expires: 1 });
            // setTimeout(this.handleAuthorizedUser, 1500)
          })
          .catch((err) => {
            this.eventErr = err;
            this.goToShowroom();
            this.loading = false;
          });
      } else {
        this.goToShowroom();
        this.loading = false;
      }
    },

    async handleAuthorizedUser() {
      try {
        const ts = new Date();
        await this.$fire.firestore
          .collection("users")
          .doc(this.user.uid)
          .update({
            loginLinkUsed: true,
            loginTimes: this.user.loginTimes ? this.user.loginTimes + 1 : 1,
            loginLinkUsedLastAt: ts,
            updatedAt: ts,
          });
        const doc = await this.$fire.firestore
          .collection("events")
          .doc(this.event)
          .get();
        const eventData = doc.data();
        this.eventStatus = eventData.eventStatus;
        this.eventTitle = this.blok.content.event_title;
        switch (this.eventStatus) {
          case "stream_ready":
            this.openStream();
            break;
          case "showroom_open":
            this.goToShowroom();
            break;
          default:
            this.toggleStreamOverlay();
            this.openStream();
        }

        this.unsubscribe = this.$fire.firestore
          .collection("events")
          .doc(this.event)
          .onSnapshot((message) => {
            const status = message.data().eventStatus;
            if (status !== this.eventStatus) {
              this.eventStatus = status;
              switch (this.eventStatus) {
                case "stream_ready":
                  this.toggleStreamOverlay(false);
                  this.openStream();
                  break;
                case "showroom_open":
                  this.goToShowroom();
                  break;
                default:
                  this.toggleStreamOverlay();
              }
            }
          });

        this.loading = false;
      } catch (error) {
        console.log(error);
        this.goToShowroom();
      }
    },

    goToRegister() {
      router.push("/register");
    },
    openStream() {
      this.pano = window.pano;
      this.updateScene();
    },
    goToShowroom() {
      router.push("/");
    },

    toggleFullscreen() {
      if (
        document.fullscreenElement ||
        document.mozFullScreenElement ||
        document.webkitFullscreenElement ||
        document.msFullscreenElement
      ) {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitExitFullscreen) {
          document.webkitExitFullscreen();
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen();
        }
      } else {
        const videoWrapper = document.querySelector("#iframe-wrapper");
        if (videoWrapper.requestFullscreen) {
          videoWrapper.requestFullscreen();
        } else if (videoWrapper.mozRequestFullScreen) {
          videoWrapper.mozRequestFullScreen();
        } else if (videoWrapper.webkitRequestFullscreen) {
          videoWrapper.webkitRequestFullscreen();
        } else if (videoWrapper.msRequestFullscreen) {
          videoWrapper.msRequestFullscreen();
        }
      }
    },

    getHotspotById(id) {
      return this.blok.content.hotspots.find((x) => {
        return x._uid === id;
      });
    },
    sceneCreatedCallback() {
      if (Array.isArray(this.blok.content.hotspots)) {
        this.blok.content.hotspots.forEach((hotspot) => {
          if (hotspot.component === "HotspotIframe") {
            this.createIframeHotspot(hotspot);
          } else if (hotspot.component === "HotspotNavi") {
            this.createNaviHotspot(hotspot);
          } else if (hotspot.component === "HotspotOpener") {
            this.createModalHotspot(hotspot);
          }
        });
      }
    },
    updateScene(scene) {
      let route;
      const currentPath = this.$route.params.pathMatch;
      if (currentPath === "") {
        route = "lokotrack";
      } else {
        route = currentPath;
      }
      if (typeof scene === "string") {
        route = scene;
      }

      // home scene change can go to a certain step
      if (this.homeStep !== "") {
        route = this.homeStep;
        this.homeStep = "";
      }

      // Create this "Scene/View"
      const viewHlookat = this.checkHotspotViewParams()
        ? this.checkHotspotViewParams()
        : this.blok.content.View[0].hlookat;
      const preview = this.blok.content.Preview
        ? `<preview url="${this.blok.content.Preview}" />`
        : `<preview type="grid(cube,64,64,512,0x222222,0x000000,0x222222);" />`;
      const image = this.blok.content.Cube
        ? `<image><cube url="${this.blok.content.Cube}" multires="512,640,1152,2304,4736" /></image>`
        : this.blok.content.Preview
        ? `<image type="sphere"><sphere url="${this.blok.content.Preview}"/></image>`
        : ``;
      const xml = `<krpano>${preview}<view hlookat="${viewHlookat}" vlookat="${this.blok.content.View[0].vlookat}" fov="${this.blok.content.View[0].fov}" fovmax="${this.blok.content.View[0].fovmax}" fovmin="${this.blok.content.View[0].fovmin}" />${image}</krpano>`;

      this.pano.set("sceneCreatedCallBack", this.sceneCreatedCallback);

      // Set this view
      this.pano.call(
        "loadxml(" +
          escape(xml) +
          ", null, MERGE, OPENBLEND(1.0, 0.0, 0.2, 0.0, linear), sceneCreatedCallBack)"
      );

      this.startView = {
        route,
        hlookat: this.blok.content.View[0].hlookat,
        vlookat: this.blok.content.View[0].vlookat,
        fov: this.blok.content.View[0].fov,
      };

      if (this.krpanoHLookAt) {
        const look = this.krpanoHLookAt;
        this.krpanoHLookAt = null;
        this.pano.call(`set(view.hlookat, ${look})`);
      }
      this.checkModalParams();
    },
    checkHotspotViewParams() {
      return this.$route.query.hlookat ? this.$route.query.hlookat : null;
    },
    checkModalParams() {
      const hsId = this.$route.hash.replace("#", "");
      if (this.$route.hash && this.getHotspotById(hsId)) {
        setTimeout(() => {
          this.openPanoModal("hs_" + hsId);
        }, 1000);
      }
    },
    parseTextToLines(str) {
      let str2 = str;
      if (str.length > 14 && str.includes(" ")) {
        let middle = Math.floor(str.length / 2);
        const before = str.lastIndexOf(" ", middle);
        const after = str.indexOf(" ", middle + 1);
        if (
          before === -1 ||
          (after !== -1 && middle - before >= after - middle)
        ) {
          middle = after;
        } else {
          middle = before;
        }
        const s1 = str.substr(0, middle);
        const s2 = str.substr(middle + 1);
        str2 = s1 + "[br]" + s2;
      }
      return str2;
    },
    getHotspotTargetTitle(target) {
      return target.content.hotspot_title
        ? target.content.hotspot_title
        : target.name;
    },
    openPanoModal(hotspot) {
      const modalEl = document.getElementById(hotspot);
      const modal = this.$bootstrap.Modal.getInstance(modalEl);
      modal.show();
      this.sendHotspotAnalytics(hotspot);
    },
    closePanoModal() {
      if (this.startView.route.includes("stage")) {
        const hNow = this.pano.get("view.hlookat");
        const vNow = this.pano.get("view.vlookat");
        this.pano.call(`lookto(${hNow}, ${vNow}, ${this.startView.fov})`);
      } else {
        this.pano.call(
          `lookto(${this.startView.hlookat}, ${this.startView.vlookat}, ${this.startView.fov})`
        );
      }
    },
    lookTo(ath, atv, fov, callback) {
      this.pano.set("mylooktocallback", callback);
      this.pano.call(
        "lookto(" +
          ath +
          ", " +
          atv +
          ", " +
          fov +
          ", tween('easeOutQuad', 0.5), true, true, mylooktocallback() )"
      );
    },
    handleNaviChange(item) {
      const hsName = "hs_" + item._uid;
      this.pano.call(`looktohotspot(${hsName})`);
    },
    sendHotspotAnalytics(hs) {},
    addTextNextToHotspot(hotspot) {
      const txtName = "txt_" + hotspot._uid;
      this.pano.call("addhotspot(" + txtName + ")");
      this.pano.set("hotspot[" + txtName + "].type", "text");
      this.pano.call("hotspot[" + txtName + "].loadstyle(hs_text_small)");
      this.pano.set(
        "hotspot[" + txtName + "].ath",
        parseFloat(hotspot.ath) || 0.0
      );
      this.pano.set(
        "hotspot[" + txtName + "].atv",
        (parseFloat(hotspot.atv) || 0.0) + 4
      );
      const hsName =
        hotspot.component === "HotspotNavi"
          ? this.getHotspotTargetTitle(hotspot.target)
          : hotspot.name;
      const txt = this.parseTextToLines(hsName);
      this.pano.set("hotspot[" + txtName + "].html", txt);
    },
    createNaviHotspot(hotspot) {
      const hsName = "hs_" + hotspot._uid;
      this.pano.call("addhotspot(" + hsName + ")");
      this.pano.set(
        "hotspot[" + hsName + "].ath",
        parseFloat(hotspot.ath) || 0.0
      );
      this.pano.set(
        "hotspot[" + hsName + "].atv",
        parseFloat(hotspot.atv) || 0.0
      );
      if (hotspot.style === "hs_small") {
        this.pano.set("hotspot[" + hsName + "].url", "/panoramas/hotspot.svg");
        this.pano.call("hotspot[" + hsName + "].loadstyle(hs_small)");
        this.pano.set("hotspot[" + hsName + "].onclick", () => {
          this.sendHotspotAnalytics(hotspot);
          this.lookTo(hotspot.ath, hotspot.atv, 50, () => {
            if (hotspot.hlookat && hotspot.hlookat !== "0") {
              router.push({
                path: `/${hotspot.target.full_slug}`,
                query: { hlookat: hotspot.hlookat },
              });
            } else {
              router.push(`/${hotspot.target.full_slug}`);
            }
          });
        });
        this.addTextNextToHotspot(hotspot);
      }
      if (hotspot.style === "hs_step") {
        this.pano.call("hotspot[" + hsName + "].loadstyle(hs_step)");
        this.pano.set("hotspot[" + hsName + "].onclick", () => {
          this.sendHotspotAnalytics(hotspot);
          this.lookTo(hotspot.ath, hotspot.atv, 50, () => {
            if (hotspot.hlookat && hotspot.hlookat !== "0") {
              router.push({
                path: `/${hotspot.target.full_slug}`,
                query: { hlookat: hotspot.hlookat },
              });
            } else {
              router.push(`/${hotspot.target.full_slug}`);
            }
          });
        });
      }
    },
    createModalHotspot(hotspot) {
      const hsName = "hs_" + hotspot._uid;

      this.pano.call("addhotspot(" + hsName + ")");
      this.pano.set(
        "hotspot[" + hsName + "].ath",
        parseFloat(hotspot.ath) || 0.0
      );
      this.pano.set(
        "hotspot[" + hsName + "].atv",
        parseFloat(hotspot.atv) || 0.0
      );
      this.pano.call("hotspot[" + hsName + "].loadstyle(hs_plus)");
      this.pano.set("hotspot[" + hsName + "].onclick", () => {
        this.sendHotspotAnalytics(hotspot);
        this.pano.call(`looktohotspot(${hsName})`);
        const modalEl = document.getElementById(hsName);
        const modal = this.$bootstrap.Modal.getInstance(modalEl);
        modal.show();
      });
      this.addTextNextToHotspot(hotspot);
    },
    createIframeHotspot(hotspot) {
      // const hsName = 'hs_' + hotspot._uid
      const hsName = "hs_" + "event-iframe";
      this.pano.call("addhotspot(" + hsName + ")");
      this.pano.set(
        "hotspot[" + hsName + "].ath",
        parseFloat(hotspot.ath) || 0.0
      );
      this.pano.set(
        "hotspot[" + hsName + "].atv",
        parseFloat(hotspot.atv) || 0.0
      );
      this.pano.set("hotspot[" + hsName + "].renderer", "css3d");
      this.pano.call("hotspot[" + hsName + "].loadstyle(hs_iframe)");

      this.pano.set("iframeCreatedCallBack", this.iframeCreatedCallBack);
      this.pano.set(
        "hotspot[" + hsName + "].onloaded",
        "add_iframe('" +
          hotspot.source.url +
          "', " +
          hotspot.width +
          ", " +
          hotspot.height +
          ");" +
          `setID(${"event-iframe"});
          iframeCreatedCallBack();
          `
      );
    },
    iframeCreatedCallBack() {
      this.eventIframeStatus = true;
      try {
        if (this.isFirefox) {
          document
            .querySelector("#event-iframe")
            .classList.add("event-iframe--firefox");
        }
      } catch (error) {}
    },
    goToTheaterMode() {
      router.push({
        path: `/${this.blok.content.event_theater.full_slug}`,
        // query: { event: this.event, token: this.eventToken },
      });
    },
    toggleStreamOverlay(status = !this.streamOverOverlay) {
      this.streamOverOverlay = status;
      if (status === false) {
        this.headerNavi = true;
      } else {
        this.headerNavi = false;
      }
    },
  },
};
</script>

<style src="@/assets/scss/event-scene.scss" lang="scss"></style>
