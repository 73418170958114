<template>
  <div class="event-theater w-screen h-screen">
    <div
      :class="[
        'event-theater__header',
        { 'event-theater__header--fullscreen': fullscreenStatus },
      ]"
    >
      <div class="event-theater__header-title">
        {{ eventTitle }}
      </div>
      <Button
        v-if="headerNavi"
        class="event-theater__header-navi"
        :label="'Go to Nordtrack™ Liveroom'"
        @click="goToShowroom()"
      />
    </div>
    <div
      :class="[
        'event-theater__container',
        { 'event-theater__container--fullscreen': fullscreenStatus },
      ]"
    >
      <client-only placeholder="Loading...">
        <div v-if="loading" class="event-theater__loading">
          <Spinner />
        </div>
        <div v-else-if="authorized" class="event-theater__iframe-container">
          <div
            id="iframe-wrapper"
            :class="[
              'event-theater__iframe-wrapper',
              { 'event-theater__iframe-wrapper--fullscreen': fullscreenStatus },
            ]"
          >
            <iframe
              :src="eventUrl"
              :class="[
                'event-theater__iframe',
                { 'event-theater__iframe--fullscreen': fullscreenStatus },
              ]"
              allowFullScreen
              mozallowfullscreen
              webkitallowfullscreen
              autoplay
            ></iframe>
          </div>

          <iframe
            :src="chatUrl"
            :class="[
              'event-theater__chat',
              { 'event-theater__chat--fullscreen': fullscreenStatus },
            ]"
          ></iframe>
        </div>
        <div v-else class="event-scene__error">User not authorized.</div>
      </client-only>
    </div>
    <div class="event-theater__footer">
      <div
        :class="[
          'event-theater__footer-bg',
          { 'event-theater__footer-bg--show': !fullscreenStatus },
        ]"
      ></div>
      <button
        v-if="!checkMobile && authorized && !fullscreenStatus"
        class="event-theater__footer-button event-theater__footer-button--360"
        @click="toggleTheaterMode()"
      >
        <SVGIcon360 />
      </button>
      <button
        v-if="!checkMobile && authorized && !fullscreenStatus"
        class="event-theater__footer-button event-theater__footer-button--theater"
      >
        <TheaterButtonIcon
          :strokeColor="fullscreenStatus ? '#fff' : '#00e2b0'"
        />
      </button>
      <button
        v-if="authorized"
        :class="[
          'event-theater__footer-button',
          { 'event-theater__footer-button--fullscreen': fullscreenStatus },
        ]"
        @click="toggleFullscreen()"
      >
        <SVGIconFullscreen :fullscreenStatus="fullscreenStatus" />
      </button>
    </div>
    <div
      v-if="streamOverOverlay"
      class="stream-over flex justify-center items-center w-screen h-screen fixed"
    >
      <div class="stream-over__container">
        <button
          class="stream-over__close-button"
          @click="toggleStreamOverlay(false)"
        >
          <CloseIcon />
        </button>
        <div class="stream-over__title">Thank you for watching</div>
        <div class="stream-over__body">
          <p>
            The live event has now ended. You can get to know details inside the
            Lokotrack™ Liveroom.
          </p>
        </div>
        <Button
          class="stream-over__button"
          :label="'Go to Lokotrack™ Liveroom'"
          @click="goToShowroom()"
        />
      </div>
    </div>
  </div>
</template>

<script>
// import Cookies from "js-cookie";

import SVGIcon360 from "@/components/icons/SVGIcon360.vue"; // Import your new SVG component
import SVGIconFullscreen from "@/components/icons/SVGIconFullscreen.vue"; // Import your new SVG component
import TheaterButtonIcon from "@/components/icons/TheaterButtonIcon.vue"; // Import your new SVG component
const router = useRouter();

const eventCookie = useCookie("event_data");

export default {
  components: {
    SVGIcon360, // Register your SVG component
    SVGIconFullscreen, // Register your SVG component
    TheaterButtonIcon, // Register your SVG component
    Button: () => import("@/components/ui/ButtonLegacy"),
    CloseIcon: () => import("@/components/ui/navigation/CloseIcon"),
    Spinner: () => import("@/components/ui/Spinner"),
  },
  props: {
    blok: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      eventToken: null,
      token: this.$route.query.token,
      event: this.$route.query.event,

      loading: true,
      eventStatus: "pre_launch",
      eventTitle: "",
      eventErr: null,
      unsubscribe: null,
      headerNavi: false,

      checkMobile: false,
      eventUrl: "",
      chatUrl: "",
      fullscreenStatus: false,

      authorized: false,

      streamOverOverlay: false,
    };
  },
  head() {
    return {
      meta: [
        {
          hid: "description",
          name: "description",
          content:
            "See live event in the Metso Liveroom. Check it out 🥰🤩 See Metso 3D 360° product demos and visit virtual stands @Metso #AggregatesLiveroom",
        },
        {
          hid: "og:description",
          name: "og:description",
          content:
            "See live event in the Metso Liveroom. Check it out 🥰🤩 See Metso 3D 360° product demos and visit virtual stands @Metso #AggregatesLiveroom",
        },
      ],
    };
  },
  watch: {
    user(oldUser, newUser) {
      // console.log('event user changed: ', oldUser, newUser)
      if (this.readyToHandle && this.user) {
        // console.log('redirecting user to correct place')
        // this.handleAuthorizedUser()
        setTimeout(this.handleAuthorizedUser, 1000);
      }
    },
  },
  mounted() {
    if (window.innerWidth <= 820) {
      this.checkMobile = true;
    }
    this.eventUrl = this.blok.content.event_url.url;
    this.chatUrl = this.blok.content.event_chat_url.url;

    const prefixes = ["", "moz", "webkit", "ms"];
    const that = this;
    prefixes.forEach(function (prefix) {
      window.addEventListener(prefix + "fullscreenchange", () => {
        that.fullscreenchanged();
      });
    });

    try {
      if (this.$route.query.event) {
        this.event = this.$route.query.event;
      } else {
        this.event = this.$route.path.split("events/")[1].split("-theater")[0];
      }
    } catch (error) {
      console.log(error);
    }

    if (this.$route.query.token) {
      eventCookie.value = JSON.stringify({
        token: this.$route.query.token,
        eventName: this.event,
        dismiss: false,
      });
      /*
      Cookies.set(
        "event_data",
        JSON.stringify({
          token: this.$route.query.token,
          eventName: this.event,
          dismiss: false,
        }),
        { expires: 1 }
      );
      */
      this.eventToken = this.$route.query.token;
    } else {
      // const cookie = Cookies.get("event_data");
      if (eventCookie.value) {
        this.eventToken = JSON.parse(
          eventCookie.value.replace("%22", '"').replace("%2C", ",")
        ).token;
      } else {
        this.eventToken = "db39e3dbe5215d4a";
      }
    }

    if (this.eventToken) {
      this.validateTokenAndSignIn();
    } else {
      router.push("/");
    }
  },
  beforeDestroy() {
    const prefixes = ["", "moz", "webkit", "ms"];
    const that = this;
    prefixes.forEach(function (prefix) {
      window.removeEventListener(prefix + "fullscreenchange", () => {
        that.fullscreenchanged();
      });
    });
    try {
      this.unsubscribe();
    } catch (error) {
      console.log("");
    }
  },
  methods: {
    fullscreenchanged() {
      if (
        document.fullscreenElement ||
        document.mozFullScreenElement ||
        document.webkitFullscreenElement ||
        document.msFullscreenElementt
      ) {
        if (!document.getElementById("fullscreen-toggle")) {
          const button = document.createElement("button");
          button.id = "fullscreen-toggle";
          button.innerHTML = `
        <svg width="37" height="37" viewBox="0 0 37 37" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M24.5372 24.537V36.8055H28.6267V28.6265H36.8057V24.537H24.5372ZM1.90735e-06 24.537V28.6265H8.17901V36.8055H12.2685V24.537H1.90735e-06ZM24.5372 -0.179199V12.0893H36.8057V7.99981H28.6267V-0.179199H24.5372ZM8.17901 -0.179199V7.99981H0V12.0893H12.2685V-0.179199H8.17901Z" fill="#00E2B2"/>
</svg>
        `;
          button.style.position = "absolute";
          button.style.bottom = "30px";
          button.style.right = "20px";
          button.style.height = "25px";
          button.style.width = "25px";
          button.onclick = () => {
            this.toggleFullscreen();
          };
          button.ontouchstart = () => {
            this.toggleFullscreen();
          };
          document.getElementById("iframe-wrapper").appendChild(button);
        }
      } else {
        document.getElementById("fullscreen-toggle").remove();
      }
    },
    async validateTokenAndSignIn() {
      if (this.$fire.auth.currentUser) {
        await this.$fire.auth.signOut();
        console.log("signed out.");
      }
      let token = this.eventToken;
      if (token.length > 16) {
        token = token.substr(0, 16);
      }
      if (token.length === 16 && /^[0-9a-zA-Z_-]+$/.test(token)) {
        setTimeout(() => {}, 100);
        this.readyToHandle = true;
        await this.$fire.auth
          .signInWithEmailAndPassword(`mo_${token}@moliveroom.com`, token)
          .then((res) => {
            console.log("signed");
            eventCookie.value = JSON.stringify({
              token,
              eventName: this.event,
              dismiss: false,
            });
            /*
            Cookies.set(
              "event_data",
              JSON.stringify({
                token,
                eventName: this.event,
                dismiss: false,
              }),
              { expires: 1 }
            );
            */
            // setTimeout(this.handleAuthorizedUser, 1500)
          })
          .catch((err) => {
            this.eventErr = err;
            this.goToShowroom();
            this.loading = false;
          });
      } else {
        this.goToShowroom();
        this.loading = false;
      }
    },

    async handleAuthorizedUser() {
      try {
        const ts = new Date();
        await this.$fire.firestore
          .collection("users")
          .doc(this.user.uid)
          .update({
            loginLinkUsed: true,
            loginTimes: this.user.loginTimes ? this.user.loginTimes + 1 : 1,
            loginLinkUsedLastAt: ts,
            updatedAt: ts,
          });
        const doc = await this.$fire.firestore
          .collection("events")
          .doc(this.event)
          .get();

        const eventData = doc.data();
        this.eventStatus = eventData.eventStatus;
        this.eventTitle = this.blok.content.event_title;
        switch (this.eventStatus) {
          case "stream_ready":
            this.openStream();
            break;
          case "showroom_open":
            this.goToShowroom();
            break;
          default:
            this.toggleStreamOverlay();
            this.openStream();
        }

        this.unsubscribe = this.$fire.firestore
          .collection("events")
          .doc(this.event)
          .onSnapshot((message) => {
            const status = message.data().eventStatus;
            if (status !== this.eventStatus) {
              this.eventStatus = status;
              switch (this.eventStatus) {
                case "stream_ready":
                  this.toggleStreamOverlay(false);
                  this.openStream();
                  break;
                case "showroom_open":
                  this.goToShowroom();
                  break;
                default:
                  this.toggleStreamOverlay();
              }
            }
          });

        this.loading = false;
      } catch (error) {
        console.log(error);
        this.goToShowroom();
      }
    },
    goToRegister() {
      router.push("/register");
    },
    openStream() {
      this.authorized = true;
    },
    goToShowroom() {
      router.push("/");
    },
    toggleTheaterMode() {
      router.push({
        path: `/${this.blok.content.event_scene.full_slug}`,
        // query: { event: this.event, token: this.eventToken },
      });
    },
    toggleFullscreen() {
      if (
        document.fullscreenElement ||
        document.mozFullScreenElement ||
        document.webkitFullscreenElement ||
        document.msFullscreenElement
      ) {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitExitFullscreen) {
          document.webkitExitFullscreen();
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen();
        }
      } else {
        const videoWrapper = document.querySelector("#iframe-wrapper");
        if (videoWrapper.requestFullscreen) {
          videoWrapper.requestFullscreen();
        } else if (videoWrapper.mozRequestFullScreen) {
          videoWrapper.mozRequestFullScreen();
        } else if (videoWrapper.webkitRequestFullscreen) {
          videoWrapper.webkitRequestFullscreen();
        } else if (videoWrapper.msRequestFullscreen) {
          videoWrapper.msRequestFullscreen();
        }
      }
    },
    toggleStreamOverlay(status = !this.streamOverOverlay) {
      this.streamOverOverlay = status;
      if (status === false) {
        this.headerNavi = true;
      } else {
        this.headerNavi = false;
      }
    },
  },
};
</script>

<style src="@/assets/scss/event-theater.scss" lang="scss" scoped></style>
