<template>
  <div class="w-6/12 md:w-3/12 p-2">
    <a
      :href="blok.link_url.url"
      target="_blank"
      class="modal-link cursor-pointer"
      @click="handleDownloadLink(blok)"
    >
      <div class="w-full mb-2">
        <img class="w-full h-auto m-auto" :src="parsedImageUrl" />
      </div>
      <div class="modal-link-text">
        {{ blok.link_text }}
        <span class="link-icon">
          <svg
            width="20"
            height="20"
            viewBox="0 0 30 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              class="fill-current text-brand-pink group-hover:text-brand-pink"
              d="M23.107 16.0828L21.8593 14.8351L15.8823 20.8122V8.82452H14.1176V20.8122L8.1405 14.8351L6.89285 16.0828L14.9999 24.1898L23.107 16.0828ZM2.64697 26.4716H27.3529V28.2363H2.64697V26.4716ZM14.1176 5.29511H15.8823V7.05981H14.1176V5.29511ZM14.1176 1.74805H15.8823V3.51275H14.1176V1.74805Z"
            />
          </svg>
        </span>
      </div>
    </a>
  </div>
</template>

<script>
export default {
  name: "ModalDownloadLinkWithPreview",
  props: {
    blok: {
      type: Object,
      required: true,
    },
  },
  computed: {
    currentSpace() {
      return this.$route.path.includes("/lokotrack")
        ? "lokotrack"
        : "nordtrack";
    },
    parsedImageUrl() {
      return this.blok.link_preview_asset &&
        this.blok.link_preview_asset.filename &&
        this.blok.link_preview_asset.filename !== ""
        ? this.blok.link_preview_asset.filename
        : this.blok.link_preview;
    },
  },
  methods: {
    handleDownloadLink(item) {},
  },
};
</script>

<style lang="scss">
.modal-link {
  color: black;
}
.modal-link-text {
  text-align: left;
  font-size: 14px;
  position: relative;
  padding-right: 20px;
}
.modal-link-text .link-icon {
  position: absolute;
  right: 0;
  top: 0;
}
</style>
