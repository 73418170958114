import { defineNuxtPlugin } from '#app/nuxt'
import { LazyBuildYourOwn, LazyButton, LazyEventContent, LazyEventHighlight, LazyEventPanel, LazyEventScene, LazyEventTeaser, LazyEventTheater, LazyFeature, LazyFooter, LazyFooterButton, LazyHotspotNavi, LazyHotspotOpener, LazyHotspotPolygon, LazyHotspotVideo, LazyImage, LazyImageBlock, LazyImageGallery, LazyKrPanoWrapper, LazyMap, LazyMapLokotrack, LazyMapNavigation, LazyMetricsPilotRequest, LazyModalDownloadLinkWithPreview, LazyModalImage, LazyModalLink, LazyModalPromoItems, LazyModalTab, LazyModalTabs, LazyModalTexts, LazyOption, LazyProduct, LazyProduct360Sequence, LazyProductHotspot, LazyProductHotspotButton, LazyProductHotspots, LazyProductRotateController, LazyProductViewer, LazyProfitCalculator, LazyRegister, LazyScene, LazySlide, LazySlides, LazySpecification, LazySummary, LazyText, LazyVerge3dV4, LazyVideo, LazyVideoGallery, LazyVideoPlayer } from '#components'
const lazyGlobalComponents = [
  ["BuildYourOwn", LazyBuildYourOwn],
["Button", LazyButton],
["EventContent", LazyEventContent],
["EventHighlight", LazyEventHighlight],
["EventPanel", LazyEventPanel],
["EventScene", LazyEventScene],
["EventTeaser", LazyEventTeaser],
["EventTheater", LazyEventTheater],
["Feature", LazyFeature],
["Footer", LazyFooter],
["FooterButton", LazyFooterButton],
["HotspotNavi", LazyHotspotNavi],
["HotspotOpener", LazyHotspotOpener],
["HotspotPolygon", LazyHotspotPolygon],
["HotspotVideo", LazyHotspotVideo],
["Image", LazyImage],
["ImageBlock", LazyImageBlock],
["ImageGallery", LazyImageGallery],
["KrPanoWrapper", LazyKrPanoWrapper],
["Map", LazyMap],
["MapLokotrack", LazyMapLokotrack],
["MapNavigation", LazyMapNavigation],
["MetricsPilotRequest", LazyMetricsPilotRequest],
["ModalDownloadLinkWithPreview", LazyModalDownloadLinkWithPreview],
["ModalImage", LazyModalImage],
["ModalLink", LazyModalLink],
["ModalPromoItems", LazyModalPromoItems],
["ModalTab", LazyModalTab],
["ModalTabs", LazyModalTabs],
["ModalTexts", LazyModalTexts],
["Option", LazyOption],
["Product", LazyProduct],
["Product360Sequence", LazyProduct360Sequence],
["ProductHotspot", LazyProductHotspot],
["ProductHotspotButton", LazyProductHotspotButton],
["ProductHotspots", LazyProductHotspots],
["ProductRotateController", LazyProductRotateController],
["ProductViewer", LazyProductViewer],
["ProfitCalculator", LazyProfitCalculator],
["Register", LazyRegister],
["Scene", LazyScene],
["Slide", LazySlide],
["Slides", LazySlides],
["Specification", LazySpecification],
["Summary", LazySummary],
["Text", LazyText],
["Verge3dV4", LazyVerge3dV4],
["Video", LazyVideo],
["VideoGallery", LazyVideoGallery],
["VideoPlayer", LazyVideoPlayer],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
