<template>
  <div class="content">
    <div
      v-if="!selectedImage && !downloadModalOpen"
      v-editable="blok"
      class="main-container"
    >
      <component
        :is="image.component"
        v-for="image in blok.images"
        :key="image._uid"
        :blok="image"
        :preview="true"
        :force-descriptions="true"
        @onChange="onChange"
      />
    </div>
    <div v-if="selectedImage" class="full-image">
      <div class="full-image-container">
        <img
          alt="Image"
          class="image-100"
          :class="{ 'blur-filter': downloadModalOpen }"
          :src="
            selectedImage.full.url
              ? selectedImage.full.url
              : selectedImage.square.url
          "
        />
        <div
          class="image-text-container"
          :class="{ 'blur-filter': downloadModalOpen }"
        >
          <div class="image-text">
            <button class="dl" @click="openDownloadModal">
              <span>
                <Download class="inline-block" />
                <span class="download-text">{{ blok.download }}</span>
              </span>
            </button>
          </div>
        </div>
      </div>
      <div class="modal-close-x">
        <ModalCloseButton class="py-3 px-6" @click="closeFullImage()" />
      </div>
      <div v-if="downloadModalOpen" class="download-modal">
        <div class="download-modal-container relative">
          <div class="download-modal-content">
            <span class="download-modal-gallery-header">{{ blok.choose }}</span>
            <span class="download-modal-text">{{ blok.chooseDesc }}</span>
            <div class="download-modal-links">
              <a
                v-if="selectedImage.full.url"
                class="download-modal-link-a"
                :href="selectedImage.full.url"
                target="_blank"
              >
                <div class="download-modal-link">
                  <span>{{ blok.landscape }}</span>
                  <span>1200x675</span>
                </div>
              </a>
              <a
                class="download-modal-link-a"
                :href="selectedImage.square.url"
                target="_blank"
              >
                <div class="download-modal-link square">
                  <span>{{ blok.square }}</span>
                  <span>1080x1080</span>
                </div>
              </a>
            </div>
          </div>
          <div class="modal-close-x">
            <ModalCloseButton class="py-3 px-3" @click="closeDownloadModal()" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Download from '~/components/gallery/Download.vue'
export default {
  name: 'ImageGallery',
  components: {
    Download,
    GalleryIndex: () => import('@/components/gallery/GalleryIndex'),
    ImageFull: () => import('@/components/gallery/ImageFull'),
    ModalCloseButton: () =>
      import('@/components/modals/modal_ui/ModalCloseButton'),
  },
  props: {
    blok: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      page: 'index',
      index: 0,
      downloadModalOpen: false,
      selectedImage: null,
    }
  },
  methods: {
    onChange(image) {
      this.selectedImage = image
    },
    onModalSwitch() {
      this.selectedImage = null
    },
    openDownloadModal() {
      this.downloadModalOpen = true
    },
    closeDownloadModal() {
      this.downloadModalOpen = false
    },
    closeFullImage() {
      this.downloadModalOpen = false
      this.selectedImage = null
    },
  },
}
</script>

<style scoped lang="scss">
.blur-filter {
  -webkit-filter: blur(2px);
  -moz-filter: blur(2px);
  -o-filter: blur(2px);
  -ms-filter: blur(2px);
  filter: blur(2px);
}

.download-modal-container {
  z-index: 120;
  background: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  --notchSize: 30px;

  clip-path: polygon(
    0% 0%,
    0% 0%,
    0% 0%,
    100% 0%,
    100% calc(100% - var(--notchSize)),
    calc(100% - var(--notchSize)) 100%,
    100% 100%,
    0% 100%
  );

  .download-modal-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 2rem 4rem 4rem;
  }

  .download-modal-links {
    margin-top: 2rem;
    justify-content: center;
    align-items: center;
    text-align: center;
    display: flex;
    flex-direction: row;
  }
  .download-modal-link {
    border: 1px solid #e5097c;
    color: #e5097c;
    text-decoration: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 232px;
    height: 127px;
  }
  .download-modal-link-a a,
  a:link,
  a:visited,
  a:hover,
  a:active {
    color: #e5097c;
    text-decoration: none;
  }
  .square {
    margin-left: 2rem;
    width: 127px;
    height: 127px;
  }
}

.content {
  flex-grow: 1;
  flex-direction: column;
  padding: 3.5rem 0 3.5rem 3.5rem;
}
.gallery-header {
  font-size: 2.25rem;
}
.main-container {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  margin-top: 2rem;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

@media (max-width: 1279px) {
  .content {
    padding: 2rem 1rem;
  }
  .gallery-header {
    font-size: 2rem;
  }
}
@media (max-width: 991px) {
  .content {
    padding: 1rem 0;
  }
}
@media (max-width: 768px) {
  .content {
    padding: 1rem 1rem 0;
  }
  .main-container {
    flex-direction: column;
    flex-grow: 1;
  }
}
.modal-close-x {
  @apply inline-block absolute top-0 right-0 cursor-pointer;
}

.full-image .modal-close-x {
  z-index: 110;
}

.full-image-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background-color: #ffffff;
  z-index: 100;

  .image-text-container {
    display: flex;
    flex-direction: row;
    margin-top: 0.8125rem;
    margin-bottom: 0.8125rem;
  }

  .image-text {
    display: flex;
    flex-grow: 1;
    color: #e5097c;
  }

  .download-text {
    margin-left: 0.5rem;
    color: #e5097c;
  }

  .image-100 {
    object-fit: fill;
    margin-top: 20px;
    margin-bottom: 10px;
    height: calc(100% - 100px);
  }
  button.dl {
    background-color: #ffffff;
    border: none;
    padding: 0;
    margin: 0;
    cursor: pointer;
    text-decoration: none;
    outline: none;
  }
}

@media (max-width: 768px) {
  .full-image-container {
    align-items: center;
    text-align: center;
  }
}
</style>
