<template>
  <div v-editable="blok" class="w-full">
    <div
      :style="{
        'background-image': 'url(' + blok.content.Background.filename + ')',
      }"
      class="fixed left-0 top-0 h-screen w-full bg-center bg-cover bg-no-repeat bg-image"
    ></div>
    <div
      class="relative container min-h-screen w-full text-white pt-20 register-content"
    >
      <div class="register-texts">
        <div class="pre-event-texts">
          <h2 v-editable="blok.content">{{ blok.content.Teaser }}</h2>
          <h1 v-editable="blok.content">
            {{ blok.content.Heading }}
          </h1>
          <h3 v-editable="blok" class="pre-event-texts-blurb">
            <RichTextRenderer :blok="blok.content.Body" />
          </h3>
          <div v-editable="blok.content" class="event-details">
            <div
              v-for="block in blok.content.Events"
              :id="block._uid"
              :key="block._uid"
              class="event-detail"
            >
              <component :is="block.component" :blok="block" />
            </div>
          </div>
        </div>
      </div>

      <div v-if="blok.content.StartDate" class="counter-wrapper">
        <PreEventTimer :time-target="blok.content.StartDate" type="counter" />
      </div>

      <div class="register-buttons">
        <Button
          :label="blok.content.CallToAction"
          :disabled="btnRegisterDisabled"
          @click="openRegisterFormModal()"
        />
      </div>
      <div class="images-box">
        <div class="imagebox-wrapper">
          <div
            v-editable="blok.content"
            class="boxes grid grid-cols-1 grid-rows-3 md:grid-cols-3 md:grid-rows-1 gap-4"
          >
            <div
              v-for="block in blok.content.Highlights"
              :id="block._uid"
              :key="block._uid"
              class="box"
            >
              <component :is="block.component" :blok="block" />
            </div>
          </div>
        </div>
      </div>
      <div class="register-footer">
        <RichTextRenderer :blok="blok.content.Footer" />
      </div>
    </div>

    <RegisterModal />
    <ThanksModal />

    <div v-show="savingData" class="left-0 right-0 top-0 m-auto mt-20">
      <Spinner />
    </div>
  </div>
</template>

<script>
export default {
  components: {
    Button: () => import("@/components/ui/ButtonLegacy"),
    PreEventTimer: () => import("@/components/register/PreEventTimer"),
    RegisterModal: () =>
      import("@/components/modals/register_modal/RegisterModal"),
    ThanksModal: () => import("@/components/modals/register_modal/ThanksModal"),
    Spinner: () => import("@/components/ui/Spinner"),
    BookingModal: () =>
      import("@/components/modals/booking_modal/BookingModal"),
  },
  props: {
    blok: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      btnRegisterDisabled: false,
      btnLoginDisabled: true,
      eventData: null,
      unsub: null,
      savingData: false,
    };
  },
  head: {
    script: [
      {
        src: "https://cdnjs.cloudflare.com/ajax/libs/jquery/3.5.1/jquery.min.js",
      },
    ],
    meta: [
      {
        hid: "description",
        name: "description",
        content:
          "Take a virtual tour in the Metso Liveroom. Check out 360° mobile equipment 3D demos, parts and services offering and virtual stands.",
      },
      {
        hid: "og:description",
        name: "og:description",
        content:
          "Check it out 🥰🤩 See Metso 3D 360° product demos and visit virtual stands @Metso #AggregatesLiveroom",
      },
    ],
  },

  mounted() {
    // this.$fireFunc.useFunctionsEmulator('http://localhost:5001')
    // REGISTER PAGE NOT ACTIVE! go home
    // if this is redirect from Pardot form sending, check submit -parameter & update user info
    setTimeout(() => {
      if (this.$route.query.submit) {
        this.openThanksModal();
        if (this.user) {
          this.savingData = true;
          this.savePardotSubmitResult(this.$route.query.submit);
        }
      }
    }, 1000);

    // this.checkEventStatus()
  },
  beforeDestroy() {
    // this.unsub()
  },
  methods: {
    openThanksModal() {
      const modalElement = document.getElementById("thanks-modal");
      const modal = this.$bootstrap.Modal.getInstance(modalElement);
      modal.show();
    },
    openRegisterFormModal() {
      const modalElement = document.getElementById("register-modal");
      const modal = this.$bootstrap.Modal.getInstance(modalElement);
      modal.show();
    },
    savePardotSubmitResult(submitQuery) {
      // console.log('savePardotSubmitResult')
      // https://metso-liveroom.web.app/register/?submit=error&first_name=Testing1&last_name=Kuubi&email=testing1%40kuubi.fi&type=distributor%2Fagent%20prospect%20%28do%20not%20sync%20to%20CRM%29&company=Kuubi&job_title=Tester&liveroom_url=https%3A%2F%2Fwww.live.mogroup.com%2Fevent%2Flaunch%2F%3Ftoken%3Dc88ea637d62a3787&errorMessage=Please%20correct%20the%20following%20errors%3A~~~%20-%20This%20field%20is%20required.~~~&allFields=&errors=true
      const pardotSend = true;
      const pardotSubmit = submitQuery;
      let pardotError = "";
      if (submitQuery === "error") {
        pardotError = this.$route.query.errorMessage
          ? this.$route.query.errorMessage
          : "unknown error";
      }
      const ts = new Date();
      const _this = this;

      this.$fire.firestore
        .collection("users")
        .doc(this.user.uid)
        .update({
          pardotDataSend: pardotSend,
          pardotDataSubmit: pardotSubmit,
          pardotDataError: pardotError,
          updatedAt: ts,
        })
        .then(function () {
          // console.log('User data successfully updated!')
          _this.savingData = false;
          _this.signOut();
        })
        .catch(function (err) {
          // The document probably doesn't exist.
          console.error("Error updating user data: ", err);
          _this.savingData = false;
        });
    },
    async signOut() {
      if (this.$fire.auth.currentUser && !this.user.superadmin) {
        await this.$fire.auth.signOut();
        // console.log('signed out.')
      }
    },
    showBooking() {
      const modalElement = document.getElementById("booking-modal");
      const modal = this.$bootstrap.Modal.getInstance(modalElement);
      modal.show();
    },
  },
};
</script>

<style lang="scss">
.bg-image {
  background-position: top center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
}
.container {
  @apply min-h-screen flex flex-col justify-start items-center text-center mx-auto;
}
.register-texts {
  margin-top: 30px;
}
.register-buttons {
  margin-bottom: 80px;
}
.register-buttons Button {
  margin-right: 20px;
  margin-bottom: 20px;
  width: 240px;
  height: 55px;
}
.images-box,
.register-footer,
.counter-wrapper,
.register-texts {
  margin-bottom: 60px;
}
.login-button {
  opacity: 0.5;
}
@media only screen and (max-width: 767px) {
  .register-buttons {
    margin-bottom: 40px;
  }
  .register-buttons Button {
    margin-right: 0;
  }
  .images-box,
  .register-footer,
  .counter-wrapper,
  .register-texts {
    margin-bottom: 30px;
  }
}

.pre-event-texts {
  max-width: 950px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.pre-event-texts-blurb {
  max-width: 590px;
}
.event-details {
  width: 100%;
  margin-top: 50px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  white-space: pre-line;
}
.event-detail {
  width: 50%;
  max-width: 370px;
  padding: 45px;
  background-image: url("@/assets/img/box_45deg_edge.svg");
  background-size: 100% 100%;
  background-position: center center;
  background-repeat: no-repeat;
}
.event-title,
.event-text {
  margin-bottom: 20px;
}
.event-text,
.event-time {
  font-size: 20px;
  font-weight: 300;
}
.event-title {
  font-size: 24px;
}
h1 {
  font-size: 70px;
  margin-bottom: 20px;
}
h2 {
  font-size: 28px;
  margin-bottom: 20px;
}
h3 {
  font-size: 18px;
  margin-bottom: 20px;
  /* font-weight: 300; */
}
@media only screen and (max-width: 767px) {
  .pre-event-texts {
    padding: 10px;
  }
  h1 {
    font-size: 36px;
    margin-bottom: 20px;
  }
  .event-details {
    flex-direction: column;
  }
  .event-detail {
    max-width: 100%;
    width: 80%;
    margin: 0 10% 20px 10%;
  }
  .event-title,
  .event-text {
    margin-bottom: 10px;
  }
}
@media only screen and (max-width: 399px) {
  .event-detail {
    padding: 20px;
  }
}

.imagebox-wrapper {
  position: relative;
  width: 1300px;
  padding: 30px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url("@/assets/img/box_45deg_edge.png");
  background-size: 100% 100%;
  background-position: center center;
  background-repeat: no-repeat;
}
.boxes {
  width: 100%;
}
.box {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 10px;
}
.box-img {
  width: auto;
  height: auto;
  max-height: 230px;
}
.box-text {
  max-width: 250px;
  font-weight: 300;
  line-height: 1.4;
}
@media only screen and (max-width: 1300px) {
  .imagebox-wrapper {
    width: 100%;
  }
}
@media only screen and (max-width: 767px) {
  .imagebox-border {
    display: none;
  }
  .imagebox-wrapper {
    background-image: none;
  }
}

.register-booking-button {
  text-transform: uppercase;
  margin-right: 20px;
  margin-bottom: 20px;
  width: 240px;
  min-height: 55px;
}
</style>
