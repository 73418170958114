<template>
  <div class="form-content relative text-left p-4">
    <div class="form-header">
      <h2 class="text-2xl">
        {{ blok.body }}
      </h2>
    </div>
    <form action novalidate @submit.prevent>
      <div class="flex form-row my-4">
        <div class="relative w-full sm:w-1/2 sm:pr-6">
          <label class="required">{{ blok.label_role }} *</label>
          <select
            id="role"
            ref="roleselect"
            v-model="formData.role"
            class="w-full px-4 py-2 rounded-none focus:outline-none border border-brand-light-gray shadow-none appearance-none placeholder-brand-light-gray text-brand-black font-light cursor-pointer"
            name="role"
          >
            <option disabled value="">Please select here</option>
            <option
              v-for="(role, index) in value_roles"
              :key="index"
              :index="index"
              :value="role"
            >
              {{ role }}
            </option>
          </select>
        </div>
      </div>
      <div class="flex form-row my-4 flex-wrap sm:flex-nowrap">
        <UiInput
          v-model="formData.firstName"
          name="firstname"
          :label="blok.label_firstname"
          error
          :required="true"
          :full-size="false"
        />
        <UiInput
          v-model="formData.lastName"
          name="lastname"
          :label="blok.label_lastname"
          error
          :required="true"
          :full-size="false"
        />
      </div>
      <div class="flex form-row my-4">
        <UiInput
          v-model="formData.userEmail"
          name="email"
          :label="blok.label_email"
          type="email"
          :required="true"
          error
          :full-size="false"
        />
        <div class="relative w-full sm:w-1/2 sm:pr-6">
          <label class="required">{{ blok.label_country }} *</label>
          <select
            id="country"
            ref="countryselect"
            v-model="formData.country"
            class="w-full px-4 py-2 rounded-none focus:outline-none border border-brand-light-gray shadow-none appearance-none placeholder-brand-light-gray text-brand-black font-light cursor-pointer"
            name="country"
          >
            <option disabled value="">Please select here</option>
            <option
              v-for="(country, index) in countries"
              :key="index"
              :index="index"
              :value="country"
            >
              {{ country }}
            </option>
          </select>
        </div>
      </div>
      <div class="flex form-row my-4 w-full flex-wrap sm:flex-nowrap">
        <UiInput
          v-model="formData.company"
          name="company"
          :label="blok.label_company"
          error
          :required="true"
          :full-size="false"
        />
        <div class="relative w-full sm:w-1/2 sm:pr-6">
          <label class="required">{{ blok.label_type }} *</label>
          <select
            id="type"
            ref="typeselect"
            v-model="formData.type"
            class="w-full px-4 py-2 rounded-none focus:outline-none border border-brand-light-gray shadow-none appearance-none placeholder-brand-light-gray text-brand-black font-light cursor-pointer"
            name="type"
          >
            <option disabled value="">Please select here</option>
            <option
              v-for="(type, index) in value_types"
              :key="index"
              :index="index"
              :value="type"
            >
              {{ type }}
            </option>
          </select>
        </div>
      </div>
      <div class="flex form-row my-4 w-full flex-wrap sm:flex-nowrap">
        <label class="required" for="expectations"
          >{{ blok.label_expectation }} *</label
        >
        <select
          id="expectations"
          ref="expectationselect"
          v-model="formData.expectations"
          class="w-full px-4 mr-6 py-2 rounded-none focus:outline-none border border-brand-light-gray shadow-none appearance-none placeholder-brand-light-gray text-brand-black font-light cursor-pointer"
          name="expectations"
        >
          <option disabled value="">Please select here</option>
          <option
            v-for="(expectation, index) in value_expectations"
            :key="index"
            :index="index"
            :value="expectation"
          >
            {{ expectation }}
          </option>
        </select>
      </div>
      <div class="flex form-row my-4 flex-wrap sm:flex-nowrap">
        <UiInput
          v-model="formData.interests"
          class="mr-6"
          name="interests"
          :label="blok.label_interests"
          error
          :required="true"
          :full-size="true"
        />
      </div>
      <div class="mt-4 mb-8">
        <BookingFormCheckbox
          v-model="formData.checkAccept"
          name="checkaccept"
          error
          :full-size="true"
        />
      </div>
      <div class="mt-4 error-message">
        <p
          :class="{
            'text-brand-orange': apiStatus === 'error',
            'text-brand-green': apiStatus === 'ok',
          }"
        >
          {{ errorMessage }}
        </p>
      </div>
      <div class="mt-4">
        <Button
          :label="blok.label_submit"
          :disabled="btnDisabled"
          @click="checkFormData()"
        />
      </div>
      <div
        v-if="sendingForm"
        class="absolute left-0 right-0 bottom-0 m-auto mb-20"
      >
        <UiSpinner />
      </div>
    </form>
  </div>
</template>

<script>
export default {
  components: {
    Input: () => import("@/components/ui/Input"),
    BookingFormCheckbox: () =>
      import("@/components/modals/booking_modal/BookingFormCheckbox"),
    Button: () => import("@/components/ui/ButtonLegacy"),
    Spinner: () => import("@/components/ui/Spinner"),
  },
  props: {
    blok: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      formData: {
        token: null,
        role: "",
        firstName: "",
        interests: "",
        expectations: "",
        lastName: "",
        userEmail: "",
        country: "",
        company: "",
        type: "",
        checkAccept: false,
      },
      errorMessage: "",
      apiStatus: "error",
      btnDisabled: false,
      sendingForm: false,
      value_expectations: [
        "Low-cost belt scales",
        "Online equipment status",
        "Advanced analytics & troubleshooting",
        "Easy to use maintenance logs",
        "Other",
      ],
      value_roles: ["Customer", "Distributor", "Other"],
      value_types: [
        "LT Lokotrack",
        "ST Mobile screen",
        "NW Portable plant",
        "Stationary crusher",
        "Other",
      ],
      countries: [
        "Afghanistan",
        "Albania",
        "Algeria",
        "American Samoa",
        "Andorra",
        "Angola",
        "Anguilla",
        "Antarctica",
        "Antigua and Barbuda",
        "Argentina",
        "Armenia",
        "Aruba",
        "Australia",
        "Austria",
        "Azerbaijan",
        "Bahamas",
        "Bahrain",
        "Bangladesh",
        "Barbados",
        "Belarus",
        "Belgium",
        "Belize",
        "Benin",
        "Bermuda",
        "Bhutan",
        "Bolivia",
        "Bosnia and Herzegovina",
        "Botswana",
        "Brazil",
        "British Indian Ocean Territory",
        "British Virgin Islands",
        "Brunei Darussalam",
        "Bulgaria",
        "Burkina Faso",
        "Burundi",
        "Cambodia",
        "Cameroon",
        "Canada",
        "Cape Verde",
        "Cayman Islands",
        "Central African Republic",
        "Chad",
        "Chile",
        "China",
        "Christmas Island",
        "Cocos (Keeling) Islands",
        "Colombia",
        "Comoros",
        "Cook Islands",
        "Costa Rica",
        "Croatia",
        "Cuba",
        "Cyprus",
        "Czech Republic",
        "Cote d'Ivoire",
        "Democratic Republic of the Congo",
        "Denmark",
        "Djibouti",
        "Dominica",
        "Dominican Republic",
        "Ecuador",
        "Egypt",
        "El Salvador",
        "Equatorial Guinea",
        "Eritrea",
        "Estonia",
        "Ethiopia",
        "Falkland Islands (Malvinas)",
        "Faroe Islands",
        "Fiji",
        "Finland",
        "France",
        "French Guiana",
        "French Polynesia",
        "French Southern Territories",
        "Gabon",
        "Gambia",
        "Georgia",
        "Germany",
        "Ghana",
        "Gibraltar",
        "Greece",
        "Greenland",
        "Grenada",
        "Guadeloupe",
        "Guam",
        "Guatemala",
        "Guernsey",
        "Guinea",
        "Guinea-Bissau",
        "Guyana",
        "Haiti",
        "Honduras",
        "Hong Kong",
        "Hungary",
        "Iceland",
        "India",
        "Indonesia",
        "Iran",
        "Iraq",
        "Ireland",
        "Isle of Man",
        "Israel",
        "Italy",
        "Jamaica",
        "Japan",
        "Jersey",
        "Jordan",
        "Kazakhstan",
        "Kenya",
        "Kiribati",
        "Kuwait",
        "Kyrgyzstan",
        "Laos",
        "Latvia",
        "Lebanon",
        "Lesotho",
        "Liberia",
        "Libya",
        "Liechtenstein",
        "Lithuania",
        "Luxembourg",
        "Macao",
        "Macedonia",
        "Madagascar",
        "Malawi",
        "Malaysia",
        "Maldives",
        "Mali",
        "Malta",
        "Marshall Islands",
        "Martinique",
        "Mauritania",
        "Mauritius",
        "Mayotte",
        "Mexico",
        "Micronesia",
        "Moldova",
        "Monaco",
        "Mongolia",
        "Montenegro",
        "Montserrat",
        "Morocco",
        "Mozambique",
        "Myanmar (Burma)",
        "Namibia",
        "Nauru",
        "Nepal",
        "Netherlands",
        "New Caledonia",
        "New Zealand",
        "Nicaragua",
        "Niger",
        "Nigeria",
        "Niue",
        "Norfolk Island",
        "North Korea",
        "North Mariana Islands",
        "Norway",
        "Oman",
        "Pakistan",
        "Palau",
        "Palestine",
        "Panama",
        "Papua New Guinea",
        "Paraguay",
        "Peru",
        "Philippines",
        "Pitcairn Islands",
        "Poland",
        "Portugal",
        "Puerto Rico",
        "Qatar",
        "Romania",
        "Russian Federation",
        "Rwanda",
        "Reunion",
        "Saint Barthélemy",
        "Saint Helena",
        "Saint Kitts and Nevis",
        "Saint Lucia",
        "Saint Pierre and Miquelon",
        "Saint Vincent and the Grenadines",
        "Samoa",
        "San Marino",
        "Sao Tome and Principe",
        "Saudi Arabia",
        "Senegal",
        "Serbia",
        "Seychelles",
        "Sierra Leone",
        "Singapore",
        "Slovakia",
        "Slovenia",
        "Solomon Islands",
        "Somalia",
        "South Africa",
        "South Korea",
        "South Sudan",
        "Spain",
        "Sri Lanka",
        "Sudan",
        "Suriname",
        "Svalbard",
        "Swaziland",
        "Sweden",
        "Switzerland",
        "Syria",
        "Taiwan",
        "Tajikistan",
        "Tanzania",
        "Thailand",
        "Togo",
        "Tokelau",
        "Tonga",
        "Trinidad and Tobago",
        "Tunisia",
        "Turkey",
        "Turkmenistan",
        "Turks and Caicos Islands",
        "Tuvalu",
        "Virgin Islands (U.S)",
        "Uganda",
        "Ukraine",
        "United Arab Emirates",
        "United Kingdom",
        "USA",
        "American Minor Outlying Islands",
        "Uruguay",
        "Uzbekistan",
        "Vanuatu",
        "Vatican City",
        "Venezuela",
        "Vietnam",
        "Wallis and Futuna Islands",
        "Western Sahara",
        "Yemen",
        "Zambia",
        "Zimbabwe",
      ],
    };
  },
  async mounted() {
    try {
      await this.$recaptcha.init();
    } catch (e) {
      console.error(e);
    }
  },
  methods: {
    checkFormValid() {
      const emailregexp =
        /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      let valid = true;
      if (
        this.formData.role === "" ||
        this.formData.firstName === "" ||
        this.formData.lastName === "" ||
        this.formData.userEmail === "" ||
        this.formData.country === "" ||
        this.formData.company === "" ||
        this.formData.type === "" ||
        this.formData.interests === "" ||
        this.formData.expectations === ""
      ) {
        valid = false;
        this.errorMessage = this.$t("register_form.error_fill_all");
      } else if (!emailregexp.test(this.formData.userEmail)) {
        valid = false;
        this.errorMessage = this.$t("register_form.error_valid_email");
      } else if (!this.formData.checkAccept) {
        valid = false;
        this.errorMessage = "Please accept the privacy notice.";
      }
      return valid;
    },
    async sendEmail() {
      const token = await this.$recaptcha.execute("login");
      this.formData.token = token;
      const sendEmailFunc =
        this.$fire.functions.httpsCallable("sendMetricsForm");
      sendEmailFunc(this.formData).then((result) => {
        if (result.data.accepted) {
          this.btnDisabled = false;
          this.sendingForm = false;
          this.$parent.$parent.closeModal();
          this.$parent.$parent.$parent.closePanoModal();
        } else {
          this.btnDisabled = false;
          this.sendingForm = false;
          this.errorMessage = this.$t("register_form.error_user_register");
        }
      });
    },
    checkFormData() {
      this.errorMessage = "";
      const checkbox = document.getElementById("checkaccept");
      if (checkbox) {
        // console.log(checkbox.checked)
        this.formData.checkAccept = checkbox.checked;
      }
      // console.log('checkFormData', this.formData)
      if (this.checkFormValid()) {
        this.btnDisabled = true;
        this.sendingForm = true;
        this.sendEmail();
      }
    },
  },
};
</script>

<style lang="css">
.pfah-wrapper {
  height: 0 !important;
  padding: 0 !important;
  overflow: hidden !important;
}
select {
  background-image: url("@/assets/img/register_form_select_arrow.svg");
  background-repeat: no-repeat;
  background-size: 14px;
  background-position: center right 10px;
}
</style>
