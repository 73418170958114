<template>
  <div id="pano"></div>
</template>

<script>
export default {
  data() {
    return {
      pano: null,
      startView: null,
      krpanoHLookAt: null,
      pageNow: null,
      homeStep: '',
      naviData: null,
    }
  },
  head: {
    script: [
      {
        src: '/krpano/krpano.js',
      },
    ],
  },
  beforeDestroy() {
    const removepano = window.removepano
    removepano('pano')
  },
  mounted() {
    const embedpano = window.embedpano
    embedpano({
      swf: null,
      xml: '/krpano/showroom.xml',
      target: 'pano',
      consolelog: false,
      onready: this.init,
    })
  },
  methods: {
    init(pano) {
      this.pano = pano
      window.pano = pano
    },
  },
}
</script>

<style>
#pano {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
@media (max-width: 767px) {
  #pano {
    /* mobile viewport bug fix */
    height: -webkit-fill-available;
  }
}
</style>
