<template>
  <div class="hotspots-container w-full">
    <ProductHotspot
      v-for="(item, index) in hotspotsData"
      :key="(item.product_hotspot_id, index)"
      :hotdata="item"
      :framenow="framenow"
      :base-url="baseUrl"
      :active="activeHotspotId === item.product_hotspot_id"
      @handlehotspotclick="handleHotspotClick($event)"
    >
    </ProductHotspot>
  </div>
</template>

<script>
export default {
  name: "ProductHotspots",
  components: {
    ProductHotspot: () => import("@/storyblok/ProductHotspot"),
  },
  props: {
    baseUrl: {
      type: String,
      required: true,
    },
    hotspotsData: {
      type: Array,
      required: true,
    },
    framenow: {
      type: Number,
      required: true,
    },
    activeHotspotId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      hideHotspots: false,
    };
  },
  methods: {
    handleHotspotClick(item) {
      // console.log('handleHotspotClick', item)
      this.$emit("activate-hotspot", item);
    },
  },
};
</script>
