export default defineNuxtRouteMiddleware(async (to, from) => {
    const { setSettingsData } = useSettings()
    const { $i18n } = useNuxtApp();
    const language = $i18n.locale.value
    const version = to.query._storyblok ? 'draft' : 'published'
    const track = to.fullPath.includes('/lokotrack') ? 'lokotrack' : 'nordtrack'
    const settingsPage = track === 'lokotrack' ? 'lokotrack/settings' : 'settings'
    const settingsStory = await useStoryblok(settingsPage, { version, language })
    const settings = settingsStory.value.content
    setSettingsData(settings)
    useState('version', () => version)
    useState('language', () => language)
    // useState('settings', () => settings)
    useState('track', () => track)
})