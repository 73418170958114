const useSettings = () => {
  const settingsData = useState("settings", () => { });

  const setSettingsData = (settings) => {
    settingsData.value = settings;
  };
  return {
    settingsData,
    setSettingsData,
  };
};
export default useSettings;
