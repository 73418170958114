<template>
  <div
    class="btn-hotspot group text-center cursor-pointer"
    @click="$emit('click')"
  >
    <div :class="{ active: active }" class="hotspot-bg relative inline-block">
      <svg
        width="45"
        height="42"
        viewBox="0 0 45 42"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          class="fill-current text-brand-orange"
          d="M29.3478 0H15.6522L4.56522 7.66864L0 21.0888L4.56522 33.8698L15.6522 41.5385H29.3478L40.4348 33.8698L45 21.0888L40.4348 7.66864L29.3478 0Z"
        />
      </svg>
      <div
        :class="active ? 'hidden' : ''"
        class="hotspot-plus absolute left-0 top-0"
        style="margin-left: 15px; margin-top: 13px"
      >
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7.11111 0V7.11111H0V8.88889H7.11111V16H8.88889V8.88889H16V7.11111H8.88889V0H7.11111Z"
            fill="white"
          />
        </svg>
      </div>
    </div>
    <div
      :class="active ? 'pt-4' : 'pt-1'"
      class="hotspot-label font-bold text-2xl text-white md:text-xl leading-none min-w-120"
      style="text-shadow: 1px 2px 4px rgba(0, 0, 0, 0.75)"
    >
      {{ label }}
    </div>
  </div>
</template>

<script>
export default {
  name: "ProductHotspotButton",
  props: {
    label: {
      type: String,
      required: true,
    },
    active: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="css">
.btn-hotspot {
  transition: all 0.3s ease-in-out;
  transform: scale(1.2);
}
.hotspot-bg {
  transition: all 0.3s ease-in-out;
}
.hotspot-bg.active {
  transform: scale(1.8);
}
.hotspot-label {
  transition: padding 0.3s ease-in-out;
}
</style>
