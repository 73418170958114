<template>
  <div>
    <h3 class="event-title">{{ blok.name }}</h3>
    <p v-if="blok.description" class="event-text">{{ blok.description }}</p>
    <p class="event-time">{{ blok.time }}</p>
  </div>
</template>

<script>
export default {
  props: {
    blok: {
      type: Object,
      required: true,
    },
  },
}
</script>
