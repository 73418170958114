import { default as _91_46_46_46slug_93Qv3uKeTLMXMeta } from "/vercel/path0/pages/[...slug].vue?macro=true";
import { default as _91_46_46_46slug_93DGxFJqF3BjMeta } from "/vercel/path0/pages/embed/[...slug].vue?macro=true";
import { default as _91_46_46_46slug_937fSaaH0vBQMeta } from "/vercel/path0/pages/lokotrack/build/[...slug].vue?macro=true";
export default [
  {
    name: "slug___en",
    path: "/:slug(.*)*",
    meta: _91_46_46_46slug_93Qv3uKeTLMXMeta || {},
    component: () => import("/vercel/path0/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "slug___de",
    path: "/de/:slug(.*)*",
    meta: _91_46_46_46slug_93Qv3uKeTLMXMeta || {},
    component: () => import("/vercel/path0/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "slug___es",
    path: "/es/:slug(.*)*",
    meta: _91_46_46_46slug_93Qv3uKeTLMXMeta || {},
    component: () => import("/vercel/path0/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "slug___fi",
    path: "/fi/:slug(.*)*",
    meta: _91_46_46_46slug_93Qv3uKeTLMXMeta || {},
    component: () => import("/vercel/path0/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "slug___fr",
    path: "/fr/:slug(.*)*",
    meta: _91_46_46_46slug_93Qv3uKeTLMXMeta || {},
    component: () => import("/vercel/path0/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "slug___pl",
    path: "/pl/:slug(.*)*",
    meta: _91_46_46_46slug_93Qv3uKeTLMXMeta || {},
    component: () => import("/vercel/path0/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "slug___ru",
    path: "/ru/:slug(.*)*",
    meta: _91_46_46_46slug_93Qv3uKeTLMXMeta || {},
    component: () => import("/vercel/path0/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "slug___tr",
    path: "/tr/:slug(.*)*",
    meta: _91_46_46_46slug_93Qv3uKeTLMXMeta || {},
    component: () => import("/vercel/path0/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "slug___ja",
    path: "/ja/:slug(.*)*",
    meta: _91_46_46_46slug_93Qv3uKeTLMXMeta || {},
    component: () => import("/vercel/path0/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "slug___pt",
    path: "/pt/:slug(.*)*",
    meta: _91_46_46_46slug_93Qv3uKeTLMXMeta || {},
    component: () => import("/vercel/path0/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "embed-slug___en",
    path: "/embed/:slug(.*)*",
    meta: _91_46_46_46slug_93DGxFJqF3BjMeta || {},
    component: () => import("/vercel/path0/pages/embed/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "embed-slug___de",
    path: "/de/embed/:slug(.*)*",
    meta: _91_46_46_46slug_93DGxFJqF3BjMeta || {},
    component: () => import("/vercel/path0/pages/embed/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "embed-slug___es",
    path: "/es/embed/:slug(.*)*",
    meta: _91_46_46_46slug_93DGxFJqF3BjMeta || {},
    component: () => import("/vercel/path0/pages/embed/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "embed-slug___fi",
    path: "/fi/embed/:slug(.*)*",
    meta: _91_46_46_46slug_93DGxFJqF3BjMeta || {},
    component: () => import("/vercel/path0/pages/embed/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "embed-slug___fr",
    path: "/fr/embed/:slug(.*)*",
    meta: _91_46_46_46slug_93DGxFJqF3BjMeta || {},
    component: () => import("/vercel/path0/pages/embed/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "embed-slug___pl",
    path: "/pl/embed/:slug(.*)*",
    meta: _91_46_46_46slug_93DGxFJqF3BjMeta || {},
    component: () => import("/vercel/path0/pages/embed/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "embed-slug___ru",
    path: "/ru/embed/:slug(.*)*",
    meta: _91_46_46_46slug_93DGxFJqF3BjMeta || {},
    component: () => import("/vercel/path0/pages/embed/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "embed-slug___tr",
    path: "/tr/embed/:slug(.*)*",
    meta: _91_46_46_46slug_93DGxFJqF3BjMeta || {},
    component: () => import("/vercel/path0/pages/embed/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "embed-slug___ja",
    path: "/ja/embed/:slug(.*)*",
    meta: _91_46_46_46slug_93DGxFJqF3BjMeta || {},
    component: () => import("/vercel/path0/pages/embed/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "embed-slug___pt",
    path: "/pt/embed/:slug(.*)*",
    meta: _91_46_46_46slug_93DGxFJqF3BjMeta || {},
    component: () => import("/vercel/path0/pages/embed/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "lokotrack-build-slug___en",
    path: "/lokotrack/build/:slug(.*)*",
    meta: _91_46_46_46slug_937fSaaH0vBQMeta || {},
    component: () => import("/vercel/path0/pages/lokotrack/build/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "lokotrack-build-slug___de",
    path: "/de/lokotrack/build/:slug(.*)*",
    meta: _91_46_46_46slug_937fSaaH0vBQMeta || {},
    component: () => import("/vercel/path0/pages/lokotrack/build/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "lokotrack-build-slug___es",
    path: "/es/lokotrack/build/:slug(.*)*",
    meta: _91_46_46_46slug_937fSaaH0vBQMeta || {},
    component: () => import("/vercel/path0/pages/lokotrack/build/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "lokotrack-build-slug___fi",
    path: "/fi/lokotrack/build/:slug(.*)*",
    meta: _91_46_46_46slug_937fSaaH0vBQMeta || {},
    component: () => import("/vercel/path0/pages/lokotrack/build/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "lokotrack-build-slug___fr",
    path: "/fr/lokotrack/build/:slug(.*)*",
    meta: _91_46_46_46slug_937fSaaH0vBQMeta || {},
    component: () => import("/vercel/path0/pages/lokotrack/build/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "lokotrack-build-slug___pl",
    path: "/pl/lokotrack/build/:slug(.*)*",
    meta: _91_46_46_46slug_937fSaaH0vBQMeta || {},
    component: () => import("/vercel/path0/pages/lokotrack/build/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "lokotrack-build-slug___ru",
    path: "/ru/lokotrack/build/:slug(.*)*",
    meta: _91_46_46_46slug_937fSaaH0vBQMeta || {},
    component: () => import("/vercel/path0/pages/lokotrack/build/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "lokotrack-build-slug___tr",
    path: "/tr/lokotrack/build/:slug(.*)*",
    meta: _91_46_46_46slug_937fSaaH0vBQMeta || {},
    component: () => import("/vercel/path0/pages/lokotrack/build/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "lokotrack-build-slug___ja",
    path: "/ja/lokotrack/build/:slug(.*)*",
    meta: _91_46_46_46slug_937fSaaH0vBQMeta || {},
    component: () => import("/vercel/path0/pages/lokotrack/build/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "lokotrack-build-slug___pt",
    path: "/pt/lokotrack/build/:slug(.*)*",
    meta: _91_46_46_46slug_937fSaaH0vBQMeta || {},
    component: () => import("/vercel/path0/pages/lokotrack/build/[...slug].vue").then(m => m.default || m)
  }
]