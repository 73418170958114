export default defineNuxtPlugin(nuxtApp => {
    const { setSettingsData } = useSettings()


    // called right before setting a new locale
    nuxtApp.hook('i18n:beforeLocaleSwitch', async ({ oldLocale, newLocale, initialSetup, context }) => {
        const version = useState("version").value;
        const track = useState("track").value;

        console.log('onBeforeLanguageSwitch', oldLocale, newLocale, initialSetup, version)
        const settingsPage = track === 'lokotrack' ? 'lokotrack/settings' : 'settings'
        const settingsStory = await useStoryblok(settingsPage, { version, language: newLocale })
        const settings = settingsStory.value.content
        setSettingsData(settings)
    })

})
