<template>
  <div class="image-container">
    <button @click="onClick(blok)">
      <img
        alt="Image"
        class="preview-image"
        :src="blok.preview.url ? blok.preview.url : blok.square.url"
      />
      <div class="image-text-container">
        <div class="image-text">
          <span>{{ blok.title }}</span>
        </div>
        <div class="image-text-download">
          <Download />
        </div>
      </div>
    </button>
  </div>
</template>

<script>
import Download from '~/components/gallery/Download.vue'
export default {
  name: 'ImageBlock',
  components: {
    Download,
  },
  props: {
    blok: {
      type: Object,
      required: true,
    },
  },
  methods: {
    onClick(image) {
      this.$emit('onChange', image)
    },
  },
}
</script>

<style scoped>
.image-container {
  display: flex;
  /* flex-grow: 1; */
  flex-direction: column;
  width: 265px;
  margin-right: 1.5rem;
  margin-bottom: 2.5rem;
}
.image-text-container {
  display: flex;
  flex-direction: row;
  margin-top: 0.8125rem;
}
.image-text {
  display: flex;
  flex-grow: 1;
  justify-content: flex-start;
}
.image-text-download {
  display: flex;
  flex-grow: 1;
  justify-content: flex-end;
}
button {
  background-color: #ffffff;
  border: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
  text-decoration: none;
  outline: none;
}
@media (max-width: 768px) {
  .preview-image {
    object-fit: fill;
    width: 100%;
    height: 100%;
  }
  .image-container {
    width: 100%;
    margin-right: 0.5rem;
    margin-left: 0.5rem;
  }
}
</style>
