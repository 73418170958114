<template>
  <div
    :id="'hotspot_' + hotdata.product_hotspot_id"
    :ref="'hotspot_' + hotdata.product_hotspot_id"
    class="hotspot-wrapper absolute"
    :class="{ hidden: frameData.alpha === 0, active: active }"
    :style="{
      left: frameData.pos_x - 50 + 'px',
      top: frameData.pos_y - 50 + 'px',
      opacity: frameData.alpha,
    }"
  >
    <ProductHotspotButton
      :active="active"
      :label="parseLabel(hotdata.name)"
      @click="handleHotspotButtonClick()"
    />
  </div>
</template>

<script>
export default {
  name: "ProductHotspot",
  components: {
    ProductHotspotButton: () => import("@/storyblok/ProductHotspotButton"),
  },
  props: {
    baseUrl: {
      type: String,
      required: true,
    },
    hotdata: {
      type: Object,
      required: true,
    },
    framenow: {
      type: Number,
      required: true,
    },
    active: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      framesData: [],
      frameData: {},
    };
  },
  watch: {
    framenow(to, from) {
      if (this.framesData) {
        this.frameData = this.framesData.find((obj) => {
          return obj.frame === this.framenow;
        });
        if (!this.frameData) {
          this.frameData = this.framesData[0];
        }
      }
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    async init() {
      const jsonUrl =
        this.baseUrl +
        "hotspot_jsons/" +
        this.hotdata.product_hotspot_id +
        ".json";

      try {
        const response = await fetch(jsonUrl);
        if (response.ok) {
          const data = await response.json();
          this.framesData = data;
          this.frameData = this.framesData[0];
        } else {
          console.log("Error loading hotspot data: ", response.status);
        }
      } catch (e) {
        console.error("Error loading hotspot data: ", e);
      }
    },
    handleHotspotButtonClick() {
      this.$emit("handlehotspotclick", this.hotdata);
    },
    parseLabel(str) {
      return str.split(" - ")[0];
    },
  },
};
</script>
