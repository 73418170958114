<template>
  <div
    :class="[
      'event-panel resizable draggable',
      { 'event-panel--hide': !viewStatus },
      { 'event-panel--theatre': theatreMode },
    ]"
  >
    <div v-if="viewStatus" class="event-panel__top"></div>
    <div v-if="viewStatus" class="event-panel__bottom"></div>
    <div v-if="viewStatus" class="event-panel__left"></div>
    <div v-if="viewStatus" class="event-panel__right"></div>
    <div
      v-if="!theatreMode"
      :class="[
        'event-panel__toggle',
        { 'event-panel__toggle--off': !viewStatus },
      ]"
    >
      <label v-if="viewStatus" class="event-panel__toggle-label"
        >Hide chat</label
      >
      <button class="event-panel__toggle-button" @click="toggleChat">
        <div v-if="viewStatus">
          <svg
            class="event-panel__toggle-icon"
            width="19"
            height="17"
            viewBox="0 0 19 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M2.10613 0L1.20564 0.884046L4.16319 3.78129C2.41705 4.71325 1.0025 6.14655 0.108827 7.88938L0 8.14076L0.108827 8.39215C0.202718 8.60164 2.58412 13.797 9.49787 13.797C9.73259 13.797 9.94384 13.7677 10.1679 13.7572C11.3427 13.7065 12.5021 13.4747 13.6034 13.0701L16.8853 16.2857L17.7944 15.3933L14.8539 12.5107L13.979 11.6518L13.8979 11.5785L11.2284 8.9452L8.66779 6.43133L6.81559 4.61924L6.37601 4.1877L5.36669 3.201L2.10613 0ZM9.49787 2.48455C8.83778 2.48734 8.17904 2.54339 7.5283 2.65214L8.71687 3.82109C8.97416 3.77105 9.23559 3.7444 9.49787 3.74148C10.6863 3.74148 11.8261 4.20498 12.6665 5.03C13.5069 5.85503 13.979 6.974 13.979 8.14076C13.976 8.39824 13.9489 8.65491 13.8979 8.90749L14.9392 9.92771C15.2466 9.08021 15.3354 8.17115 15.1976 7.28175C15.0599 6.39236 14.6998 5.55031 14.1497 4.83083C15.5912 5.59228 16.7827 6.74016 17.5852 8.14076C17.0655 9.05466 16.3748 9.86416 15.5495 10.5268L16.4586 11.4214C17.4751 10.5897 18.3024 9.55765 18.8869 8.39215L19.0021 8.14076L18.8869 7.88938C18.7909 7.66941 16.4095 2.48455 9.49787 2.48455ZM4.84602 4.85178C4.12555 5.80524 3.73658 6.96092 3.73658 8.1481C3.73658 9.33527 4.12555 10.491 4.84602 11.4444C3.40398 10.6865 2.21215 9.54047 1.41049 8.14076C2.21794 6.74916 3.40866 5.60923 4.84602 4.85178ZM5.93643 5.52843L7.77796 7.3447C7.59942 7.69232 7.53714 8.08638 7.6 8.47077C7.66286 8.85515 7.84764 9.21027 8.12805 9.48556C8.40846 9.76084 8.77018 9.94225 9.16172 10.004C9.55326 10.0657 9.95465 10.0045 10.3087 9.82925L12.1588 11.6371C11.3972 12.2173 10.4619 12.5347 9.49787 12.54C8.3094 12.54 7.1696 12.0765 6.32923 11.2515C5.48885 10.4265 5.01673 9.30752 5.01673 8.14076C5.02217 7.19436 5.34544 6.27614 5.93643 5.52843Z"
              fill="white"
            />
          </svg>
        </div>
        <div v-if="!viewStatus">
          <svg
            class="event-panel__toggle-icon"
            width="22"
            height="19"
            viewBox="0 0 22 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 0V14.8192H4.23077V19L9.60543 14.8192H22V0H0ZM1.69231 1.64658H20.3077V13.1726H9.01048L5.92308 15.5751V13.1726H1.69231V1.64658Z"
              fill="white"
            />
          </svg>
        </div>
      </button>
    </div>
    <iframe :src="blok.source.url" :class="['event-panel__iframe']"></iframe>
  </div>
</template>

<script>
import chatIcon from "~/assets/img/icons/chat_bubble_outline-white-36dp.svg";

export default {
  props: {
    blok: {
      type: Object,
      required: true,
    },
    theatreMode: {
      default: false,
    },
  },
  watch: {
    theatreMode() {
      if (this.theatreMode) {
        this.viewStatus = true;
      }
    },
  },
  data() {
    return {
      viewStatus: true,
      chatIcon,
      windowWidth: "auto",
    };
  },
  mounted() {
    if (window.innerWidth < 768) {
      document.querySelector(".event-panel").style.width = "100vw !important;";
    } else {
    }
  },
  methods: {
    dragMoveListener(event) {
      const target = event.target;
      // keep the dragged position in the data-x/data-y attributes
      const x = (parseFloat(target.getAttribute("data-x")) || 0) + event.dx;
      const y = (parseFloat(target.getAttribute("data-y")) || 0) + event.dy;

      // translate the element
      target.style.transform = "translate(" + x + "px, " + y + "px)";

      // update the posiion attributes
      target.setAttribute("data-x", x);
      target.setAttribute("data-y", y);
    },

    toggleChat() {
      this.viewStatus = !this.viewStatus;
    },
  },
};
</script>

<style scoped>
.event-panel {
  background-color: #fff;
  bottom: 90px;
  box-sizing: border-box;
  left: calc(100vw - 320px);
  max-height: 100vh;
  max-width: 100vw;
  min-height: 320px;
  min-width: 320px;
  position: absolute;
  right: 0px;
  top: 115px;
  transition:
    max-height 0.25s ease,
    min-height 0.25s ease;
  touch-action: none;
  user-select: none;
  width: 320px;
  z-index: 99;
}

.event-panel__top {
  height: 66px;
  left: 0;
  position: absolute;
  top: 0;
  width: calc(100% - 60px);
}

.event-panel__bottom {
  bottom: 0;
  height: 15px;
  left: 0;
  position: absolute;
  width: 100%;
}

.event-panel__left {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 15px;
}

.event-panel__right {
  bottom: 0;
  height: 100%;
  position: absolute;
  right: 0;
  width: 15px;
}

.event-panel--hide {
  max-height: 0 !important;
  min-height: 0 !important;
}

.event-panel--theatre {
  height: calc(100vh - 66px - 75px);
  top: 66px;
}

.event-panel__toggle {
  right: 0;
  position: absolute;
  top: -35px;
  transition: all 0.25s ease;
}

.event-panel__toggle-icon {
  height: 18px;
  width: auto;
}

.event-panel__toggle-button {
  align-items: center;
  background-color: #eb2814;
  border: none;
  color: #fff;
  display: flex;
  height: 35px;
  justify-content: center;
  outline: none;
  transition: all 0.25s ease;
  width: 40px;
}

.event-panel__toggle-label {
  align-items: center;
  color: #fff;
  display: flex;
  font-size: 14px;
  height: 100%;
  justify-content: flex-end;
  position: absolute;
  right: 0;
  transform: translateX(-60px);
  width: 180px;
}

.event-panel__toggle-button:hover {
  background-color: #00e2b0;
}

.event-panel__iframe {
  border: 0;
  height: 100%;
  overflow: hidden;
  width: 100%;
}

@media only screen and (max-width: 1400px) {
  .event-panel {
    bottom: 80px;
  }
}

@media only screen and (max-width: 820px) {
  .event-panel {
    height: 320px;
    left: 0;
    max-height: 50vh;
    max-width: 100vw;
    min-height: 320px;
    min-width: 300px;
    top: unset;
    right: 0px;
    width: 100%;
    bottom: 40px;
  }

  .event-panel__toggle {
    top: -35px;
  }
}
</style>
